//用于指令，只允许输入框输入数字
// 示例：<input v-only-number />
export default {
    name: 'only-number',
    directive: {
        mounted(el) {
            el.onkeydown = (event) => {
                if (!(event.keyCode === 46) && !(event.keyCode === 8) && !(event.keyCode === 9) && !(event.keyCode === 37) && !(event.keyCode === 39)) {
                    if (!((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105))) {
                        event.returnValue = false;
                    }
                }
            }
        }
    }
}