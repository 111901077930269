import {ref, reactive} from "vue";
import {caseCommentSend, caseCommentGetList, caseCommentGetUnreadList, caseCommentReadAll} from "@/api/case";
import {ElMessage} from "element-plus";
import {userTypeEnum} from "@/util/enum";

function useSendCaseComment() {
    const caseComment = reactive({
        caseId: '',
        sendSource: userTypeEnum.Hospital,
        commentContent: '',
        fileId: '',
        facDoctorName: '',
        facHospitalName: '',
        addTime: '',
        isRead: 0
    })
    const initCaseComment = () => {
        caseComment.commentContent = ''
        caseComment.fileId = ''
        caseComment.isRead = 0
    }
    const sendCaseCommentLoading = ref(false)
    const sendCaseComment = async () => {
        if (sendCaseCommentLoading.value) return
        if (!caseComment.fileId && !caseComment.commentContent) {
            ElMessage.error('没有发送任何文本和文件，请检查')
            return
        }
        sendCaseCommentLoading.value = true
        try {
            let res = await caseCommentSend(caseComment)
            if (res.code === 0) {
                return res.data
            } else {
                ElMessage.error(res.message)
            }
        } catch (e) {
            console.log(e)
            ElMessage.error('请求错误，请检查网络或者联系客服')
        } finally {
            sendCaseCommentLoading.value = false
        }
    }
    return {
        caseComment,
        initCaseComment,
        sendCaseCommentLoading,
        sendCaseComment
    }
}

function useGetListCaseComment() {
    const caseCommentList = ref([])
    const getListCaseCommentLoading = ref(false)
    const getListCaseComment = async caseId => {
        if (getListCaseCommentLoading.value) return
        getListCaseCommentLoading.value = true
        let res = await caseCommentGetList({id: caseId})
        if (res && res.length) {
            caseCommentList.value = res
        } else {
            caseCommentList.value = []
        }
        getListCaseCommentLoading.value = false
    }
    return {
        caseCommentList,
        getListCaseCommentLoading,
        getListCaseComment
    }
}

function useGetUnreadListCaseComment() {
    const caseCommentUnreadList = ref([])
    const getUnreadListCaseCommentLoading = ref(false)
    const getUnreadListCaseComment = async caseId => {
        if (getUnreadListCaseCommentLoading.value) return
        getUnreadListCaseCommentLoading.value = true
        let res = await caseCommentGetUnreadList({id: caseId})
        if (res && res.length) {
            caseCommentUnreadList.value = res
        } else {
            caseCommentUnreadList.value = []
        }
        getUnreadListCaseCommentLoading.value = false
    }
    return {
        caseCommentUnreadList,
        getUnreadListCaseCommentLoading,
        getUnreadListCaseComment
    }
}

function useReadAllCaseComment() {
    const readAllCaseCommentLoading = ref(false)
    const readAllCaseCommentResult = ref(false)
    const readAllCaseComment = async caseId => {
        if (readAllCaseCommentLoading.value) return
        readAllCaseCommentResult.value = false
        readAllCaseCommentLoading.value = true
        readAllCaseCommentResult.value = await caseCommentReadAll({id: caseId})
        readAllCaseCommentLoading.value = false
    }
    return {
        readAllCaseCommentLoading,
        readAllCaseCommentResult,
        readAllCaseComment
    }
}

export {
    useSendCaseComment,
    useGetListCaseComment,
    useGetUnreadListCaseComment,
    useReadAllCaseComment
}