<template>
    <router-view/>
</template>

<style lang="scss">
html, body {
    height: 100%;
}

body {
    display: flex;
    margin: 0;
    padding: 0;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    //text-align: center;
    color: #2c3e50;
    flex-grow: 1;
}

.text-primary {
    color: #409EFF !important;
}

.text-success {
    color: #67C23A !important;
}

.text-warning {
    color: #E6A23C !important;
}

.text-danger {
    color: #F56C6C !important;
}

.text-info {
    color: #909399 !important;
}

.flex-row {
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
}

.flex-col {
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
}

.flex-grow {
    -webkit-flex-grow: 1;
    flex-grow: 1;
}

.text-center {
    text-align: center;
}

//页面容器
.view-container {
    margin-top: 20px;
    border: 1px solid #edf1f2;
    border-radius: 2px 2px 0 0;
}

//页面只有form
.view-form {
    display: flex;
    padding: 30px;
    background-color: white;
}

//页面搜索容器
.view-search {
    display: flex;
    padding-top: 20px;
    padding-left: 10px;
    background-color: #f6f8f8;
}

//页面表格容器
.view-table {
    align-items: stretch;
}

//页面表格分页容器
.view-pagination {
    padding: 7px;
    background-color: white;
    display: flex;
}

.input-small {
    width: 100px !important;;
}

.w-120 {
    width: 120px !important;;
}

.w-135 {
    width: 135px !important;;
}

.w-150 {
    width: 150px !important;;
}

.w-250 {
    width: 250px !important;;
}

.input-medium {
    width: 200px !important;
}

.input-large {
    width: 300px !important;;
}

.input-extra {
    width: 500px !important;;
}

// 树结构页面样式
.view-tree {
    min-height: 700px;
    align-items: stretch;
    -webkit-align-items: stretch;
    background-color: white;
}

.view-tree-left {
    padding: 10px;
    width: 250px;
    //border-right: 2px solid #edf1f2;
}

.view-tree-right {
    //padding: 10px;
    background-color: white;
}

.el-table .warning-row {
    --el-table-tr-bg-color: var(--el-color-warning-light-9);
}

.el-table .success-row {
    --el-table-tr-bg-color: var(--el-color-success-light-9);
}

.el-table .danger-row {
    --el-table-tr-bg-color: var(--el-color-danger-light-9);
}

// 左右分页页面样式
.view-list {
    min-height: 700px;
    align-items: stretch;
    -webkit-align-items: stretch;
    background-color: white;
}

.view-list-left {
    padding: 5px;
    flex-shrink: 0;
    width: 320px;
}
.view-list-left .el-form-item {
    margin-bottom: 10px!important;
}
.view-list-right {
    background-color: white;
    flex: 1;
    width: 0;
}

// 图片上传
.avatar-uploader .avatar {
    width: 150px;
    height: 150px;
    display: block;
}

.avatar-uploader .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    text-align: center;
}

.message-badge .el-badge__content {
    margin-top: 10px;
}

// 普通线图和柱状图的图标高度
.chart-normal {
    height: 450px;
}

.chart-small {
    height: 300px;
}

.ml-20 {
    margin-left: 20px;
}

.radio-plain {
    .el-radio-button__original-radio:checked + .el-radio-button__inner {
        color: var(--el-color-primary) !important;
        background-color: var(--el-color-primary-light-9) !important;
        border-color: var(--el-color-primary-light-7) !important;
        box-shadow: -1px 0 0 0 var(--el-color-primary) !important;
    }
}

.radio-success {
    .el-radio-button__original-radio:checked + .el-radio-button__inner {
        color: var(--el-radio-button-checked-text-color, var(--el-color-white)) !important;
        background-color: var(--el-color-success) !important;
        border-color: var(--el-color-success) !important;
        box-shadow: -1px 0 0 0 var(--el-color-success) !important;
    }
}

.radio-warning {
    .el-radio-button__original-radio:checked + .el-radio-button__inner {
        color: var(--el-radio-button-checked-text-color, var(--el-color-white)) !important;
        background-color: var(--el-color-warning) !important;
        border-color: var(--el-color-warning) !important;
        box-shadow: -1px 0 0 0 var(--el-color-warning) !important;
    }
}

.radio-danger {
    .el-radio-button__original-radio:checked + .el-radio-button__inner {
        color: var(--el-radio-button-checked-text-color, var(--el-color-white)) !important;
        background-color: var(--el-color-danger) !important;
        border-color: var(--el-color-danger) !important;
        box-shadow: -1px 0 0 0 var(--el-color-danger) !important;
    }
}


.el-checkbox-button.is-checked.checkbox-success .el-checkbox-button__inner {
    color: var(--el-radio-button-checked-text-color, var(--el-color-white)) !important;
    background-color: var(--el-color-success) !important;
    border-color: var(--el-color-success) !important;
    box-shadow: -1px 0 0 0 var(--el-color-success) !important;
}

.el-checkbox-button.is-checked.checkbox-warning .el-checkbox-button__inner {
    color: var(--el-radio-button-checked-text-color, var(--el-color-white)) !important;
    background-color: var(--el-color-warning) !important;
    border-color: var(--el-color-warning) !important;
    box-shadow: -1px 0 0 0 var(--el-color-warning) !important;
}

.el-checkbox-button.is-checked.checkbox-danger .el-checkbox-button__inner {
    color: var(--el-radio-button-checked-text-color, var(--el-color-white));
    background-color: var(--el-color-danger);
    border-color: var(--el-color-danger);
    box-shadow: -1px 0 0 0 var(--el-color-danger-success);
}

.compact-form .el-form-item {
    margin-bottom: 15px !important;
}

.compact-tab>.el-tabs__content{
    padding: 0 !important;
}

.compact-checkbox>.el-checkbox{
    margin-right: 0!important;
}

td.compact-cell > .cell {
    padding: 1px !important;
}
</style>
