/**
 * item2是否在item1后面(右边)
 * @param item1
 * @param item2
 * @returns {boolean}
 */
const isAfter = (item1, item2) => {
    item1 = item1.toUpperCase()
    item2 = item2.toUpperCase()
    if (item1.indexOf('A') >= 0) {
        if (item2.indexOf('B') >= 0) {
            return true
        } else if (item2.indexOf('A') >= 0) {
            return item2 < item1
        }
        return false
    }
    if (item1.indexOf('B') >= 0) {
        return item2 > item1
    }
    if (item1.indexOf('C') >= 0) {
        if (item2.indexOf('D') >= 0) {
            return true
        } else if (item2.indexOf('C') >= 0) {
            return item2 < item1
        }
        return false
    }
    if (item1.indexOf('D') >= 0) {
        return item2 > item1
    }
}
/**
 * 桥体字符串获取十字对象
 * @param pontic 桥体字符串
 * @returns {{pos4Arr: *[], pos3Arr: *[], pos2Arr: *[], pos1Arr: *[]}}
 */
const getPosObj = pontic => {
    let res = {
        pos1Arr: [],
        pos2Arr: [],
        pos3Arr: [],
        pos4Arr: []
    }
    if (!pontic) return res
    let ponticArr = pontic.split('|')
    let posArr = []
    for (let ponticItem of ponticArr) {
        posArr = posArr.concat(ponticItem.split(','))
    }
    res.pos1Arr = posArr.filter(x => x.indexOf('A') >= 0).map(x => {
        return {
            code: x.substring(1),
            isBegin: ponticArr.some(a => a.indexOf(x) === 0),
            isEnd: ponticArr.some(a => a.indexOf(x) === a.length - 2),
        }
    })
    res.pos2Arr = posArr.filter(x => x.indexOf('B') >= 0).map(x => {
        return {
            code: x.substring(1),
            isBegin: ponticArr.some(a => a.indexOf(x) === 0),
            isEnd: ponticArr.some(a => a.indexOf(x) === a.length - 2),
        }
    })
    res.pos3Arr = posArr.filter(x => x.indexOf('D') >= 0).map(x => {
        return {
            code: x.substring(1),
            isBegin: ponticArr.some(a => a.indexOf(x) === 0),
            isEnd: ponticArr.some(a => a.indexOf(x) === a.length - 2),
        }
    })
    res.pos4Arr = posArr.filter(x => x.indexOf('C') >= 0).map(x => {
        return {
            code: x.substring(1),
            isBegin: ponticArr.some(a => a.indexOf(x) === 0),
            isEnd: ponticArr.some(a => a.indexOf(x) === a.length - 2),
        }
    })
    return res
}

/**
 * 1234转ABDC
 * @param pos
 * @param posPart
 * @returns {string}
 */
const getFdiStr = (pos, posPart) => {
    if (posPart === 1) {
        posPart = 'A'
    } else if (posPart === 2) {
        posPart = 'B'
    } else if (posPart === 4) {
        posPart = 'C'
    } else if (posPart === 3) {
        posPart = 'D'
    }
    return (posPart + pos).toUpperCase()
}

const isUpper = fdiStr => {
    fdiStr = fdiStr.toUpperCase()
    return fdiStr.indexOf('A') >= 0 || fdiStr.indexOf('B') >= 0
}
const isLower = fdiStr => {
    fdiStr = fdiStr.toUpperCase()
    return fdiStr.indexOf('C') >= 0 || fdiStr.indexOf('D') >= 0
}
/**
 * 获取两个牙位中间的所有牙位
 * @param beginFdiStr
 * @param endFdiStr
 * @returns {*[]}
 */
const getBetween = (beginFdiStr, endFdiStr) => {
    let res = []
    beginFdiStr = beginFdiStr.toUpperCase()
    endFdiStr = endFdiStr.toUpperCase()
    if (!isAfter(beginFdiStr, endFdiStr)) {
        let tmp = beginFdiStr
        beginFdiStr = endFdiStr
        endFdiStr = tmp
    }
    if (beginFdiStr.indexOf('A') >= 0) {
        if (endFdiStr.indexOf('B') >= 0) {
            for (let i = parseInt(beginFdiStr.substring(1)); i > 0; i--) {
                res.push('A' + i)
            }
            for (let i = 1; i <= parseInt(endFdiStr.substring(1)); i++) {
                res.push('B' + i)
            }
        } else if (endFdiStr.indexOf('A') >= 0) {
            for (let i = parseInt(beginFdiStr.substring(1)); i >= parseInt(endFdiStr.substring(1)); i--) {
                res.push('A' + i)
            }
        }
    } else if (beginFdiStr.indexOf('B') >= 0) {
        for (let i = parseInt(beginFdiStr.substring(1)); i <= parseInt(endFdiStr.substring(1)); i++) {
            res.push('B' + i)
        }
    } else if (beginFdiStr.indexOf('C') >= 0) {
        if (endFdiStr.indexOf('D') >= 0) {
            for (let i = parseInt(beginFdiStr.substring(1)); i > 0; i--) {
                res.push('C' + i)
            }
            for (let i = 1; i <= parseInt(endFdiStr.substring(1)); i++) {
                res.push('D' + i)
            }
        } else if (endFdiStr.indexOf('C') >= 0) {
            for (let i = parseInt(beginFdiStr.substring(1)); i >= parseInt(endFdiStr.substring(1)); i--) {
                res.push('C' + i)
            }
        }
    } else if (beginFdiStr.indexOf('D') >= 0) {
        for (let i = parseInt(beginFdiStr.substring(1)); i <= parseInt(endFdiStr.substring(1)); i++) {
            res.push('D' + i)
        }
    }
    return res
}

export {
    getBetween,
    getPosObj,
    getFdiStr,
    isUpper,
    isLower,
    isAfter,
}