import {useStore} from 'vuex'
//获取缓存的数据字典
function useGetDataValueListFromStore() {
    const store = useStore()
    const getDataValueListFromStore = nameEn => {
        let dataValueList = store.state.dataValueList
        let data = dataValueList.find(x => x.nameEn === nameEn)
        if (data) {
            return data.dataValueList
        } else {
            return []
        }
    }
    const getDataIdFromStore = nameEn => {
        let dataValueList = store.state.dataValueList
        let data = dataValueList.find(x => x.nameEn === nameEn)
        if (data) {
            return data.id
        } else {
            return 0
        }
    }
    return {
        getDataValueListFromStore,
        getDataIdFromStore
    }
}

export {
    useGetDataValueListFromStore
}