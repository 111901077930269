//通用获取枚举列表函数
export const getEnumList = enums => {
    let enumList = []
    for (let item in enums) {
        enumList.push({
            id: enums[item],
            name: item
        })
    }
    return enumList
}
//性别
export const genderList = ['男', '女']
// 审核状态
export const auditStateEnum = {
    不通过: -1,
    待审核: 0,
    通过: 1,
    无需审核: 2,
}
export const auditState = getEnumList(auditStateEnum)
// 订单状态
export const caseStateEnum = {
    已创建: 0,
    入件: 7,
    加工中: 10,
    加工完成: 20,
    已装箱: 30,
    已发货: 40,
    已收货: 50,
}
export const caseState = getEnumList(caseStateEnum)
// 订单类型
export const caseTypeEnum = {
    正常: 1,
    返工: 2,
    返修: 3,
    免费: 4,
    试做: 5,
}
export const caseType = getEnumList(caseTypeEnum)
// 试戴状态
export const tryStateEnum = {
    未试戴: 1,
    试戴中: 2,
    试戴调整: 3,
    试戴完成: 4,
}
export const tryState = getEnumList(tryStateEnum)
// 订单来源
export const orderSourceEnum = {
    工厂后台: 1,
    业务员APP: 2,
    医生APP: 3,
    医生第三方: 4,
    工厂后台销售: 5,
    医生下单平台: 6,
}
// 提醒方法名称
export const doctorAlertMethodEnum = {
    消息全部已读: 'readAll',
    订单审核: 'caseAudit',
    订单发货: 'caseOut',
    更新缓存: 'updateStore',
    订单沟通: 'caseComment',
}
// 提醒类型
export const doctorAlertTypeEnum = {
    订单审核不通过: 1,
    订单审核通过: 2,
    订单发货: 3
}
export const doctorAlertType = getEnumList(doctorAlertTypeEnum)

// 用户类型
export const userTypeEnum = {
    Factory: 1,
    Hospital: 3,
}
//文件用途
export const fileUseEnum = {
    质检印章: 1,
    订单图片: 2,
    订单文件: 3,
    订单文件切片: 4,
    订单口扫文件: 5,
}
export const fileUse = getEnumList(fileUseEnum)

// 发货类型
export const sendTypeEnum = {
    销售: 1,
    快递公司: 2,
    物流: 3
}
export const sendType = getEnumList(sendTypeEnum)
// 收货人
export const receiverTypeEnum = {
    医院收货: 1,
    科室收货: 2,
    销售: 3
}
export const receiverType = getEnumList(receiverTypeEnum)
// 牙位类型
export const toothTypeEnum = {
    单冠: 1,
    桥体: 2,
    缺失: 3
}
export const toothType = getEnumList(toothTypeEnum)
// 应用类型
export const appType = {
    工厂医生下单平台: 2,
}