<template>
    <div class="flex-row tooth-container">
        <div class="jaw">
            <div><span v-show="!!upperJaw">上颌</span></div>
            <div><span v-show="!!lowerJaw">下颌</span></div>
        </div>
        <div class="flex-column">
            <div class="flex-row border-bottom-dash">
                <div class="border-right-dash tooth-part tooth-left">{{ pos1 }}</div>
                <div class="tooth-part tooth-right">{{ pos2 }}</div>
            </div>
            <div class="flex-row">
                <div class="border-right-dash tooth-part tooth-left">{{ pos4 }}</div>
                <div class="tooth-part tooth-right">{{ pos3 }}</div>
            </div>
        </div>
    </div>
</template>
<!--牙位组件-->
<!--用法示例-->
<!--重要 table使用一定要加上key-->
<!--<table-tooth-->
<!--    :key="scope.$index"-->
<!--    :pos1="scope.row.pos1"-->
<!--    :pos2="scope.row.pos2"-->
<!--    :pos3="scope.row.pos3"-->
<!--    :pos4="scope.row.pos4"-->
<!--    :upper-jaw="scope.row.upperJaw"-->
<!--    :lower-jaw="scope.row.lowerJaw"-->
<!--/>-->
<script setup>
import {ref, reactive, inject, watch, toRef, defineProps, defineEmits, computed} from 'vue'

const props = defineProps({
    pos1: String,//左上
    pos2: String,//右上
    pos3: String,//右下
    pos4: String,//左下
    upperJaw: Number,
    lowerJaw: Number,
})
const pos1 = ref(props.pos1)
const pos2 = ref(props.pos2)
const pos3 = ref(props.pos3)
const pos4 = ref(props.pos4)
const upperJaw = ref(props.upperJaw)
const lowerJaw = ref(props.lowerJaw)
</script>

<style scoped lang="scss">
.tooth-container {
    width: 150px;
    font-size: 11px;
}

.jaw {
    width: 40px;
    div{
        height: 20px;
    }
}

.tooth-part {
    width: 55px;
    height: 20px;
}

.border-bottom-dash {
    border-bottom: 1px dashed #777;
    div{
        padding-bottom: 2px;
    }
}

.border-right-dash {
    border-right: 1px dashed #777;
    text-align: right;
}

.tooth-left {
    padding-right: 3px;
}

.tooth-right {
    padding-left: 3px;
}
</style>