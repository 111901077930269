<template>
    <el-container style="height: 100%;width: 100%;">
        <el-aside :width="asideWidth" style="overflow-x: hidden">
            <el-scrollbar style="background-color: #545c64">
                <el-menu
                    ref="menuRef"
                    active-text-color="#ffd04b"
                    background-color="#545c64"
                    class="home-menu"
                    :collapse="isCollapse"
                    :router="true"
                    :unique-opened="true"
                    :default-active="defaultMenu"
                    text-color="#fff">
                    <el-menu-item class="text-primary" index="/admin/welcome" @click="setBreadcrumb('首页','首页')">
                        <el-icon>
                            <HomeFilled></HomeFilled>
                        </el-icon>
                        <span style="font-size: 17px;">存知科技</span>
                    </el-menu-item>
                    <el-sub-menu v-for="menuOne in modules" :index="menuOne.nameEn" :title="menuOne.nameCn">
                        <template #title>
                            <el-icon>
                                <setting v-if="menuOne.nameEn==='system'"/>
                                <List v-if="menuOne.nameEn==='case'"/>
                            </el-icon>
                            <span :class="curParentName===menuOne.nameCn?'active-menu-text':'default-menu-text'">{{
                                    menuOne.nameCn
                                }}</span>
                        </template>
                        <el-menu-item v-for="menuTwo in menuOne.children" :index="setPath(menuTwo.nameEn)"
                                      @click="setBreadcrumb(menuTwo.nameCn,menuTwo.parentNameCn)">
                            {{ menuTwo.nameCn }}
                        </el-menu-item>
                    </el-sub-menu>
                </el-menu>
            </el-scrollbar>
        </el-aside>
        <el-container>
            <el-header>
                <el-menu mode="horizontal" :ellipsis="false">
                    <el-menu-item index="0" @click="toggleMenu">
                        <el-icon>
                            <DArrowLeft v-if="isCollapse===false"/>
                            <DArrowRight v-if="isCollapse===true"/>
                        </el-icon>&nbsp;
                    </el-menu-item>
                    <el-menu-item index="1">
                        <el-icon>
                            <User/>
                        </el-icon>
                        欢迎，
                        <span class="text-danger">{{ currentDoctor.facDoctorName }}</span>
                    </el-menu-item>
                    <div class="flex-grow"></div>
                    <el-menu-item index="factoryName">
                        <span class="text-info"> {{ currentDoctor.factoryName }}</span>
                    </el-menu-item>
                    <el-menu-item index="hospitalName">
                        <span class="text-info"> {{ currentDoctor.facHospitalName }}</span>
                    </el-menu-item>
                    <el-menu-item index="refresh" class="text-success" @click="refreshStore"
                                  title="点击可以刷新数据，例如设置、产品、客户等数据">
                        <el-icon>
                            <Refresh/>
                        </el-icon>&nbsp;
                    </el-menu-item>
                    <el-menu-item index="myMessage" class="text-warning" @click="readMessage" title="我的通知">
                        <el-badge :is-dot="!!unreadNum" class="message-badge">
                            <el-icon>
                                <Message/>
                            </el-icon>&nbsp;
                        </el-badge>
                    </el-menu-item>
                    <el-menu-item index="caseComment" class="text-warning" @click="showUnreadCaseComment"
                                  title="订单沟通消息（未读）">
                        <el-badge :is-dot="!!caseCommentUnreadList.length" class="message-badge">
                            <el-icon>
                                <ChatDotRound/>
                            </el-icon>&nbsp;
                        </el-badge>
                    </el-menu-item>
                    <el-menu-item index="logOut" class="text-danger" @click="handleLogOut" title="退出">
                        <el-icon>
                            <SwitchButton/>
                        </el-icon>&nbsp;
                    </el-menu-item>
                </el-menu>
            </el-header>
            <el-main class="home-main">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item>{{ curParentName }}</el-breadcrumb-item>
                    <el-breadcrumb-item>{{ curPageName }}</el-breadcrumb-item>
                </el-breadcrumb>
                <router-view v-loading="refreshStoreLoading" v-if="isRouterAlive"/>
            </el-main>
            <el-footer style="height:25px;padding:3px;">
                <el-row>
                    <el-col :span="24" class="text-center text-info" style="font-size: 10px;">
                        此软件由存知网络科技有限公司提供
                    </el-col>
                </el-row>
            </el-footer>
        </el-container>
        <el-drawer v-model="caseCommentVisible" title="订单沟通消息（未处理）">
            <el-table :data="caseCommentUnreadList" :show-header="false" v-loading="getUnreadListCaseCommentLoading">
                <el-table-column>
                    <template #default="scope">
                        <div style="display: flex;justify-content:space-between;">
                            <b>{{ scope.row.caseNo }}</b>
                            <span>{{ moment(scope.row.addTime).format('yyyy-MM-DD HH:mm:ss') }}</span>
                        </div>
                        <div style="display: flex;justify-content:space-between;align-items: flex-end;">
                            <div class="flex-col">
                                <span class="text-primary">{{ scope.row.factoryName }}</span>
                            </div>
                            <div>
                                <span class="text-danger">{{ scope.row.unreadNum }}</span>
                                <span style="margin-right: 20px;">条未读</span>
                                <el-button plain type="primary" @click="handleCaseComment(scope.row.caseId)"
                                           :loading="readAllCaseCommentLoading">前往>>
                                </el-button>
                                <el-button plain type="warning" @click="readAllCaseComment(scope.row.caseId)"
                                           :loading="readAllCaseCommentLoading">已处理
                                </el-button>
                            </div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </el-drawer>
    </el-container>
</template>

<script setup>
import {useStore} from 'vuex'
import {computed, nextTick, onMounted, inject, provide, ref, reactive, onBeforeUnmount, watch} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import {ElMessage} from "element-plus";
import {ElNotification} from "element-plus";
import {useLogout} from "@/use/system/useDoctorUser";
import {useGetWebStore} from "@/use/system/useDoctorUser";
import moment from 'moment'
import {useGetUnreadNumFacDoctorAlert} from "@/use/system/useFacDoctorAlert";

const {
    getWebStore,
    getWebStoreLoading
} = useGetWebStore()
const isRouterAlive = ref(true)
const store = useStore()
const router = useRouter()
const route = useRoute()
const currentDoctor = computed(() => store.state.currentDoctor)

// 侧边栏收起or展开
let collapseCache = localStorage.getItem('collapseCache')
const isCollapse = ref(collapseCache === 'true')
const asideWidth = ref(collapseCache === 'true' ? "65px" : "180px")
const toggleMenu = () => {
    if (isCollapse.value) {
        isCollapse.value = false
        asideWidth.value = "180px"
        localStorage.setItem('collapseCache', 'false')
    } else {
        isCollapse.value = true
        asideWidth.value = "65px"
        localStorage.setItem('collapseCache', 'true')
    }
}

const modules = [
    {
        nameCn: '订单管理',
        nameEn: 'case',
        children: [
            {
                nameCn: '订单录入',
                nameEn: 'myCase',
                parentNameCn: '订单管理'
            },
            {
                nameCn: '订单查询',
                nameEn: 'caseList',
                parentNameCn: '订单管理'
            }
        ]
    },
    {
        nameCn: '系统管理',
        nameEn: 'system',
        children: [
            {
                nameCn: '我的消息',
                nameEn: 'myMessage',
                parentNameCn: '系统管理'
            },
            {
                nameCn: '重置密码',
                nameEn: 'resetPassword',
                parentNameCn: '系统管理'
            },
            {
                nameCn: '登录日志',
                nameEn: 'loginRecord',
                parentNameCn: '系统管理'
            },
        ]
    }
]
const setPath = nameEn => {
    return '/admin/' + nameEn
}
const {
    getUnreadNumFacDoctorAlert
} = useGetUnreadNumFacDoctorAlert()
onMounted(async () => {
    await start()
    refreshStoreLoading.value = true
    let productStr = sessionStorage.getItem('productList')
    if (!productStr || JSON.parse(productStr).length === 0) {
        await refreshData()
    }
    refreshStoreLoading.value = false
    await getUnreadListCaseComment()
    let resUnreadNum = await getUnreadNumFacDoctorAlert()
    store.commit('setUnreadNum', resUnreadNum ? resUnreadNum : 0)
})
// 刷新缓存
const refreshStoreLoading = ref(false)
const refreshStore = async () => {
    refreshStoreLoading.value = true
    await refreshData()
    ElMessage.success('完成刷新！')
    refreshStoreLoading.value = false
    isRouterAlive.value = false
    await nextTick(() => {
        isRouterAlive.value = true
    })
}
const refreshData = async () => {
    await getWebStore()
}
// 退出
const {logOut} = useLogout()
const handleLogOut = async () => {
    await disconnect()
    setBreadcrumb('', '')
    logOut()
}
onBeforeUnmount(async () => {
    await disconnect()
})
// 我的消息
const readMessage = () => {
    setBreadcrumb('系统管理', '我的消息')
    router.push('myMessage')
}
const unreadNum = computed(() => {
    return store.state.unreadNum
})
//用来刷新的时候 保持菜单选中状态
const defaultMenu = ref(route.path)
const menuRef = ref()

//面包屑导航
let pageName = sessionStorage.getItem('pageName') || '首页'
let parentPageName = sessionStorage.getItem('parentPageName') || '首页'
const curPageName = ref(pageName)
const curParentName = ref(parentPageName)
const setBreadcrumb = (pageName, parentName) => {
    curPageName.value = pageName
    sessionStorage.setItem('pageName', pageName)
    curParentName.value = parentName
    sessionStorage.setItem('parentPageName', parentName)
}

//推送！
import * as signalR from "@microsoft/signalr"
import {doctorAlertMethodEnum} from "@/util/enum";

// 长链接对象
const connection = new signalR.HubConnectionBuilder()
    .withUrl("/messageHub", {accessTokenFactory: () => sessionStorage.getItem("token")})
    .configureLogging(signalR.LogLevel.Error)
    .build();

// 开始连接
async function start() {
    try {
        await connection.start()
        console.log("SignalR Connected.");
    } catch (err) {
        console.log(err);
        setTimeout(start, 10000);//错误重连
    }
}

const toPage = inject('toPage')
// 未读沟通消息
import {useGetUnreadListCaseComment, useReadAllCaseComment} from "@/use/case/useCaseComment";

const {
    caseCommentUnreadList,
    getUnreadListCaseComment,
    getUnreadListCaseCommentLoading
} = useGetUnreadListCaseComment()
const caseCommentVisible = ref(false)
const showUnreadCaseComment = () => {
    getUnreadListCaseComment()
    caseCommentVisible.value = true
}
const {
    readAllCaseCommentResult,
    readAllCaseCommentLoading,
    readAllCaseComment
} = useReadAllCaseComment()
watch(readAllCaseCommentResult, async (n, o) => {
    if (!!n) {
        await getUnreadListCaseComment()
    }
})
const handleCaseComment = caseId => {
    newCaseComment.caseId = caseId
    newCaseComment.commentContent = ''
    newCaseComment.fileId = ''
    caseCommentVisible.value = false
    toCaseComment(caseId)
}
const toCaseComment = caseId => {
    if (route.name !== 'myCase') {
        router.push({
            name: 'myCase',
            query: {
                id: caseId,
                type: 'comment'
            }
        })
    } else {
        showCaseCommentNum.value++
    }
}
// 关闭连接
const disconnect = async () => {
    console.log('disconnect')
    await connection.stop()
}
connection.onclose(async error => {
    console.log('error', error)
    if (!!error) await start()
})
//获取服务端长链接的推送信息
connection.on(doctorAlertMethodEnum.消息全部已读, message => {
    store.commit('setUnreadNum', 0)
})
connection.on(doctorAlertMethodEnum.订单审核, alertMessage => {
    store.commit('setUnreadNum', 1)
    let _notify = ElNotification.warning({
        title: alertMessage.Title,
        position: 'bottom-right',
        dangerouslyUseHTMLString: true,
        message: `<p>${alertMessage.Message}</p><p>${alertMessage.AddTime}</p>`,
        duration: 0,
        onClick: () => {
            toPage('myMessage')
            _notify.close()
        }
    })
})
connection.on(doctorAlertMethodEnum.订单发货, alertMessage => {
    store.commit('setUnreadNum', 1)
    let _notify = ElNotification.warning({
        title: alertMessage.Title,
        position: 'bottom-right',
        dangerouslyUseHTMLString: true,
        message: `<p>${alertMessage.Message}</p><p>${alertMessage.AddTime}</p>`,
        duration: 0,
        onClick: () => {
            toPage('myMessage')
            _notify.close()
        }
    })
})
connection.on(doctorAlertMethodEnum.更新缓存, msg => {
    console.log(doctorAlertMethodEnum.更新缓存)
    getWebStore()
})
const newCaseCommentNum = ref(0)//新沟通消息
provide('newCaseCommentNum', newCaseCommentNum)
const showCaseCommentNum = ref(0)//确认要查看新消息
provide('showCaseCommentNum', showCaseCommentNum)
const newCaseComment = reactive({
    id: '',
    caseId: '',
    sendSource: '',
    commentContent: '',
    fileId: '',
    fileToken: '',
    facDoctorName: '',
    facHospitalName: '',
    facWorkerName: '',
    factoryName: '',
    addTime: '',
    addTimeStr: '',
    isRead: 0
})
provide('newCaseComment', newCaseComment)
connection.on(doctorAlertMethodEnum.订单沟通, comment => {
    newCaseCommentNum.value++
    if (!!comment) {
        for (let field in newCaseComment) {
            let dbField = field.substring(0, 1).toUpperCase() + field.substring(1)
            newCaseComment[field] = comment[dbField]
        }
    }
    let _notify = ElNotification.warning({
        title: '新的订单沟通信息',
        position: 'bottom-right',
        dangerouslyUseHTMLString: true,
        message: `<p>${comment.FacWorkerName + "  :  " + comment.FactoryName}</p><p>${comment.AddTimeStr}</p>`,
        duration: 0,
        onClick: () => {
            toCaseComment(comment.CaseId)
            _notify.close()
        }
    })
})
</script>

<style scoped>
.home-menu {
    min-height: 100%;
}

.active-menu-text {
    color: #ffd04b;
}

.default-menu-text {
    color: rgb(255, 255, 255);
}

.home-main {
    background-color: #f0f3f4;
}

.el-menu--horizontal {
    border-bottom: none !important;
}
</style>