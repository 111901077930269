import {reactive, ref, inject, toRaw} from 'vue'
import moment from 'moment'
import {facDoctorAlertGetPage, facDoctorAlertGetUnreadNum, facDoctorAlertReadAll, facDoctorAlertReadOne} from "@/api/system";

// 用户操作记录翻页
function useGetPageFacDoctorAlert() {
    const getPageFacDoctorAlertParam = reactive({
        beginTime: '',
        endTime: '',
        doctorAlertType: '',
        isRead: -1,
        pageIndex: 1,
        pageSize: 10,
    })
    const getPageFacDoctorAlertParamEmpty = JSON.parse(JSON.stringify(toRaw(getPageFacDoctorAlertParam)))
    const getPageFacDoctorAlertResult = reactive({
        totalCount: 0,
        totalPages: 0,
    })
    const facDoctorAlertPage = ref([])
    const initGetPageFacDoctorAlertParam = () => {
        Object.assign(getPageFacDoctorAlertParam, getPageFacDoctorAlertParamEmpty)
    }
    const getPageFacDoctorAlertLoading = ref(false)
    const getPageFacDoctorAlert = async () => {
        if (getPageFacDoctorAlertLoading.value) return
        getPageFacDoctorAlertLoading.value = true
        let data = await facDoctorAlertGetPage(getPageFacDoctorAlertParam)
        if (data && data.list && data.list.length) {
            facDoctorAlertPage.value = data.list
            getPageFacDoctorAlertResult.totalPages = Math.ceil(data.totalCount / getPageFacDoctorAlertParam.pageSize)
            getPageFacDoctorAlertResult.totalCount = data.totalCount
        } else {
            facDoctorAlertPage.value = []
            getPageFacDoctorAlertResult.totalPages = 0
            getPageFacDoctorAlertResult.totalCount = 0
        }
        getPageFacDoctorAlertLoading.value = false
    }
    return {
        getPageFacDoctorAlertParam,
        getPageFacDoctorAlertResult,
        facDoctorAlertPage,
        initGetPageFacDoctorAlertParam,
        getPageFacDoctorAlertLoading,
        getPageFacDoctorAlert
    }
}

// 获取未读提醒数量
function useGetUnreadNumFacDoctorAlert() {
    const getUnreadNumFacDoctorAlertLoading = ref(false)
    const getUnreadNumFacDoctorAlert = async () => {
        if (getUnreadNumFacDoctorAlertLoading.value) return
        getUnreadNumFacDoctorAlertLoading.value = true
        let res = await facDoctorAlertGetUnreadNum({})
        getUnreadNumFacDoctorAlertLoading.value = false
        return res
    }
    return {
        getUnreadNumFacDoctorAlertLoading,
        getUnreadNumFacDoctorAlert,
    }
}

function useFacDoctorAlertReadAll() {
    const readAllFacDoctorAlertLoading = ref(false)
    const readAllFacDoctorAlertResult = ref(false)
    const readAllFacDoctorAlert = async () => {
        if (readAllFacDoctorAlertLoading.value) return
        readAllFacDoctorAlertResult.value = false
        readAllFacDoctorAlertLoading.value = true
        readAllFacDoctorAlertResult.value = await facDoctorAlertReadAll({})
        readAllFacDoctorAlertLoading.value = false
    }
    return {
        readAllFacDoctorAlert,
        readAllFacDoctorAlertLoading,
        readAllFacDoctorAlertResult,
    }
}

function useFacDoctorAlertReadOne() {
    const readOneFacDoctorAlertLoading = ref(false)
    const readOneFacDoctorAlertResult = ref(false)
    const readOneFacDoctorAlert = async id => {
        if (readOneFacDoctorAlertLoading.value) return
        readOneFacDoctorAlertResult.value = false
        readOneFacDoctorAlertLoading.value = true
        readOneFacDoctorAlertResult.value = await facDoctorAlertReadOne({id: id})
        readOneFacDoctorAlertLoading.value = false
    }
    return {
        readOneFacDoctorAlert,
        readOneFacDoctorAlertLoading,
        readOneFacDoctorAlertResult,
    }
}

export {
    useGetPageFacDoctorAlert,
    useGetUnreadNumFacDoctorAlert,
    useFacDoctorAlertReadAll,
    useFacDoctorAlertReadOne,
}