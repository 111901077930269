import axios from "axios";
import {ElNotification} from 'element-plus'
import router from './../router'
import {ref} from 'vue'
import store from "./../store";

function logOut() {
    store.commit('setToken', '')
    store.commit('setExpireTime', '')
    store.commit('setRefreshToken', '')
    store.commit('setRefreshTokenExpireTime', '')
    sessionStorage.clear()
}

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
axios.defaults.headers.post['Content-Type'] = 'application/json';
let config = {
    baseURL: process.env.BASE_URL || process.env.apiUrl || "",
    // baseURL:'http://localhost:8082',
    timeout: 150 * 1000, // Timeout
    withCredentials: false // Check cross-site Access-Control
}

const _axios = axios.create(config)

_axios.interceptors.request.use(
    function (config) {
        // Do something before request is sent
        let token = localStorage.getItem('token')
        if ((!!token) && token !== 'undefined') config.headers['Authorization'] = 'Bearer ' + token
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
)
//当前是否已经有登录失败弹窗，放置多次弹窗
const isReLoginAlert = ref(false)
// Add a response interceptor
_axios.interceptors.response.use(
    function (response) {
        // Do something with response data
        return response;
    },
    function (error) {
        //请求错误  控制台打印错误信息
        console.log('interceptors', error);
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    if (!isReLoginAlert.value) {
                        isReLoginAlert.value = true
                        ElNotification({
                            title: '登录失效',
                            message: '请重新登录平台',
                            type: 'error',
                            onClose: () => {
                                isReLoginAlert.value = false
                            }
                        })
                    }
                    logOut()
                    router.push('/')
                    break
                case 500:
                    ElNotification({
                        title: '失败',
                        message: '内部服务错误，请检查网络或者联系客服',
                        type: 'error',
                    })
                    break
                default:
                    return Promise.reject(error);
            }
        }
    }
)

function cleanArray(actual) {
    const newArray = []
    for (let i = 0; i < actual.length; i++) {
        if (actual[i]) {
            newArray.push(actual[i])
        }
    }
    return newArray
}

function getQueryFromJson(json) {
    if (!json) return ''
    return cleanArray(Object.keys(json).map(key => {
        if (json[key] === undefined || json[key] === null) return ''
        if (Array.isArray(json[key])) {
            if (json[key].length === 0) {
                return ''
            } else {
                let strArr = []
                for (let item of json[key]) {
                    strArr.push(encodeURIComponent(key) + '[]=' + encodeURIComponent(item))
                }
                return strArr.join('&')
            }
        }
        return encodeURIComponent(key) + '=' +
            encodeURIComponent(json[key])
    })).join('&')
}

function post(url, data, prefix = 'api/factory/') {
    return new Promise((resolve, reject) => {
        // if (process.env.NODE_ENV === 'production' && url.substr(0,4)!=='http') {
        //   url = window.location.protocol+'//'+window.location.host + '/' + url
        // }
        _axios.post(prefix + url, data)
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
                reject(err)
            });
    })
}

function postFile(url, data, header, prefix = 'api/factory/') {
    return new Promise((resolve, reject) => {
        // if (process.env.NODE_ENV === 'production' && url.substr(0,4)!=='http') {
        //   url = window.location.protocol+'//'+window.location.host + '/' + url
        // }
        _axios.post(prefix + url, data, header)
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
                reject(err)
            });
    })
}

function get(url, data, prefix = 'api/factory/') {
    return new Promise((resolve, reject) => {
        // if (process.env.NODE_ENV === 'production' && url.substr(0,4)!=='http') {
        //   url = window.location.protocol+'//'+window.location.host + '/' + url
        // }
        _axios.get(prefix + url, {params: data})
            .then(res => {
                console.log(res)
                resolve(res.data)
            })
            .catch(err => {
                reject(err)
            })
    })
}

function factoryGet(url, data, prefix = 'api/factory/') {
    return new Promise((resolve, reject) => {
        // if (process.env.NODE_ENV === 'production' && url.substr(0,4)!=='http') {
        //   url = window.location.protocol+'//'+window.location.host + '/' + url
        // }

        _axios.get(prefix + url, {params: data})
            .then(res => {
                if (res) {
                    let resultData = res.data
                    if (resultData.code === 0) {
                        resolve(resultData.data)
                    } else {
                        console.log('factoryGet fail', resultData)
                        resolve(null)
                        ElNotification({
                            title: '失败',
                            message: resultData.message,
                            type: 'error',
                        })
                    }
                } else {
                    resolve(null)
                }
            })
            .catch(err => {
                console.log('factoryGet error', err)
                resolve(null)
                ElNotification({
                    title: '失败',
                    message: '请求失败，请检查网络或者联系客服',
                    type: 'error',
                })
            })
    })
}


function factoryPost(url, data, prefix = 'api/factory/') {
    return new Promise((resolve, reject) => {
        // if (process.env.NODE_ENV === 'production' && url.substr(0,4)!=='http') {
        //   url = window.location.protocol+'//'+window.location.host + '/' + url
        // }
        _axios.post(prefix + url, data)
            .then(res => {
                if (res) {
                    let resultData = res.data
                    //Code是0 保存成功  返回数据中的Data部分  并弹出提示框
                    if (resultData.code === 0) {
                        ElNotification.success('操作成功')
                    } else {
                        //Code不是0  弹出错误框
                        console.log('factoryPost fail', resultData)
                        ElNotification({
                            title: '失败',
                            message: resultData.message,
                            type: 'error',
                        })
                    }
                    resolve(resultData.code === 0)
                } else {
                    resolve(false)
                }
            })
            .catch(err => {
                console.log('factoryPost error', err)
                resolve(false)
                ElNotification({
                    title: '失败',
                    message: '请求失败，请检查网络或者联系客服',
                    type: 'error',
                })
            });
    })
}

/**
 * 返回data数据的工厂post方法
 * @param url
 * @param data
 * @param prefix
 * @returns {Promise<unknown>}
 */
function factoryPostData(url, data, prefix = 'api/factory/') {
    return new Promise((resolve, reject) => {
        // if (process.env.NODE_ENV === 'production' && url.substr(0,4)!=='http') {
        //   url = window.location.protocol+'//'+window.location.host + '/' + url
        // }
        _axios.post(prefix + url, data)
            .then(res => {
                if (res) {
                    let resultData = res.data
                    //Code是0 保存成功  返回数据中的Data部分  并弹出提示框
                    if (resultData.code === 0) {
                        ElNotification.success('操作成功')
                        resolve(resultData.data)
                    } else {
                        //Code不是0  弹出错误框
                        console.log('factoryPost fail', resultData)
                        ElNotification({
                            title: '失败',
                            message: resultData.message,
                            type: 'error',
                        })
                        resolve(false)
                    }
                } else {
                    resolve(false)
                }
            })
            .catch(err => {
                console.log('factoryPost error', err)
                resolve(false)
                ElNotification({
                    title: '失败',
                    message: '请求失败，请检查网络或者联系客服',
                    type: 'error',
                })
            });
    })
}

export default {
    post,
    get,
    postFile,
    factoryGet,
    factoryPost,
    factoryPostData,
    getQueryFromJson
}
