import {createRouter, createWebHistory} from 'vue-router'
import login from '../views/login'
import homeView from '../views/homeView'
import myCase from '../views/case/myCase'

const routes = [
    {
        path: '/',
        name: 'login',
        component: login
    },
    {
        path: '/admin',
        name: 'home',
        component: homeView,
        children: [
            {
                path: 'resetPassword',
                name: 'resetPassword',
                component: () => import('../views/system/resetPassword'),
            },
            {
                path: 'loginRecord',
                name: 'loginRecord',
                component: () => import('../views/system/loginRecord'),
            },
            {
                path: 'myMessage',
                name: 'myMessage',
                component: () => import('../views/system/myMessage'),
            },
            {
                path: 'myCase',
                name: 'myCase',
                component: myCase,
            },
            {
                path: 'caseList',
                name: 'caseList',
                component: () => import('../views/case/caseList'),
            },
            {
                path: 'welcome',
                name: 'welcome',
                component: () => import('../views/welcome'),
            },
        ]
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

// 路由跳转前的拦截器
router.beforeEach((to, from) => {
    // console.log(to)
    // console.log(from)
    // 一般用来判断 没有token的时候 访问任何页面都会跳转回登录页面
    // 1，判断当前跳转页面（to.name）是否是登录页面，如果不是判断有无token
    // 2，判断sessionStorage或者localStorage里面是不是有token
    // 没有token跳转到login
    // 有token直接调用next方法
    // 3，判断是否有页面访问的权限（首页都可以访问)
    if (to.name !== 'login' && to.path.indexOf('\/help') < 0
        && to.path.indexOf('\/myMessage') < 0 && to.path.indexOf('\/welcome') < 0) {
        let token = localStorage.getItem('token')
        if (!token || token === 'undefined') {
            return {name: 'login'}
        } else {
            // if (to.name !== 'homeInfo') {
            //     let auth = sessionStorage.getItem('auth')
            //     if (auth.indexOf('\"' + to.name + '\"') < 0) {
            //         ElMessage.error('没有页面访问权限，请检查')
            //         return {name: from.name}
            //     }
            // }

        }
    }
})

export default router
