import http from "@/util/axios";

// 订单
export const caseSave = data => http.post('case/save', data)
export const caseGetPage = data => http.factoryGet('case/get_page', data)
export const caseGetSimplePage = data => http.factoryGet('case/get_simple_page', data)
export const caseGet = data => http.factoryGet('case/get', data)
export const caseGetNo = data => http.factoryGet('case/get_no', data)
export const caseValid = data => http.factoryPost('case/valid', data)
export const caseGetHistoryList = data => http.factoryGet('case/get_history_List', data)
export const caseExpressGet = data => http.factoryGet('case/get_case_express', data)

export const caseProductStepGetSimpleList = data => http.factoryGet('caseProductStep/get_simple_list', data)

// 订单沟通
export const caseCommentSend = data => http.post('caseComment/send', data)
export const caseCommentReadAll = data => http.factoryPost('caseComment/doctor_read_all', data)
export const caseCommentGetList = data => http.factoryGet('caseComment/get_list', data)
export const caseCommentGetUnreadList = data => http.factoryGet('caseComment/get_doctor_unread_list', data)