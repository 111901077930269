<template>
    <div class="flex-column tooth-container">
        <el-space>
            <el-radio-group v-model="innerToothType" size="small" @change="changeToothType">
                <el-radio-button v-for="item in toothType" :key="item.id" :label="item.id"
                                 :class="{'radio-success':item.id===toothTypeEnum.桥体,'radio-danger':item.id===toothTypeEnum.缺失}">
                    {{ item.name }}
                </el-radio-button>
            </el-radio-group>
            <el-checkbox label="上颌" v-model="innerUpperJaw" :true-label="1" :false-label="0" size="small"
                         @change="changeUpperJaw"></el-checkbox>
            <el-checkbox label="下颌" v-model="innerLowerJaw" :true-label="1" :false-label="0" size="small"
                         @change="changeLowerJaw"></el-checkbox>
        </el-space>
        <div class="flex-column">
            <div class="flex-row border-bottom-dash">
                <div class="border-right-dash tooth-part tooth-left">
                    <el-checkbox-group v-model="pos1Arr" size="small">
                        <el-checkbox-button v-for="item in decreaseArr" :value="item" @change="changePos(item,1)"
                                            :label="item" :class="getToothClass(item,1)"
                                            :key="item"></el-checkbox-button>
                    </el-checkbox-group>
                </div>
                <div class="tooth-part tooth-right">
                    <el-checkbox-group v-model="pos2Arr" size="small">
                        <el-checkbox-button v-for="item in increaseArr" :value="item" @change="changePos(item,2)"
                                            :label="item" :class="getToothClass(item,2)"
                                            :key="item"></el-checkbox-button>
                    </el-checkbox-group>
                </div>
            </div>
            <div class="flex-row">
                <div class="border-right-dash tooth-part tooth-left">
                    <el-checkbox-group v-model="pos4Arr" size="small">
                        <el-checkbox-button v-for="item in decreaseArr" :value="item" @change="changePos(item,4)"
                                            :label="item" :class="getToothClass(item,4)"
                                            :key="item"></el-checkbox-button>
                    </el-checkbox-group>
                </div>
                <div class="tooth-part tooth-right">
                    <el-checkbox-group v-model="pos3Arr" size="small">
                        <el-checkbox-button v-for="item in increaseArr" :value="item" @change="changePos(item,3)"
                                            :label="item" :class="getToothClass(item,3)"
                                            :key="item"></el-checkbox-button>
                    </el-checkbox-group>
                </div>
            </div>
        </div>
    </div>
</template>
<!--牙位组件-->
<!--用法示例-->
<!--重要 table使用一定要加上key-->
<!--<table-tooth-input-->
<!--    :key="scope.$index"-->
<!--    v-model:pos1="toothNoValue.pos1"-->
<!--    v-model:pos2="toothNoValue.pos2"-->
<!--    v-model:pos3="toothNoValue.pos3"-->
<!--    v-model:pos4="toothNoValue.pos4"-->
<!--    v-model:upper-jaw="toothNoValue.upperJaw"-->
<!--    v-model:lower-jaw="toothNoValue.lowerJaw"-->
<!--    @change-tooth="getToothNum"-->
<!--&gt;</table-tooth-input-->
<script setup>
import {ref, reactive, inject, watch, toRef, defineProps, defineEmits, computed, onMounted} from 'vue'
import {toothType, toothTypeEnum} from "@/util/enum";
import {isAfter, getFdiStr, isUpper, isLower, getBetween} from "@/use/case/useTooth";

const props = defineProps({
    pos1: String,//左上
    pos2: String,//右上
    pos3: String,//右下
    pos4: String,//左下
    upperJaw: Number,
    lowerJaw: Number,
    toothType: Number,//牙位类型
    pontic: String,//桥体
    missing1: String,
    missing2: String,
    missing3: String,
    missing4: String,
})
const emits = defineEmits(['update:pos1', 'update:pos2', 'update:pos3', 'update:pos4', 'update:upperJaw', 'update:lowerJaw', 'update:toothType', 'changeTooth',
    'update:missing1', 'update:missing2', 'update:missing3', 'update:missing4', 'update:pontic'])

const innerToothType = ref(1)
watch(() => props.toothType, (newVal, oldVal) => {
    if (innerToothType.value === toothTypeEnum.桥体 && newVal !== toothTypeEnum.桥体) {
        innerToothType.value = newVal
        if (newVal === toothTypeEnum.单冠) {
            changePosFromParent(props.pos1, 1)
            changePosFromParent(props.pos2, 2)
            changePosFromParent(props.pos3, 3)
            changePosFromParent(props.pos4, 4)
        } else if (newVal === toothTypeEnum.缺失) {
            changePosFromParent(props.missing1, 1)
            changePosFromParent(props.missing2, 2)
            changePosFromParent(props.missing3, 3)
            changePosFromParent(props.missing4, 4)
        }
    } else {
        innerToothType.value = newVal
    }

})
const innerUpperJaw = ref(0)
watch(() => props.upperJaw, (newVal, oldVal) => {
    innerUpperJaw.value = newVal
})
const innerLowerJaw = ref(0)
watch(() => props.lowerJaw, (newVal, oldVal) => {
    innerLowerJaw.value = newVal
})
/**
 * 清空牙位选择框
 */
const clearPosArr = () => {
    pos1Arr.value = []
    pos2Arr.value = []
    pos3Arr.value = []
    pos4Arr.value = []
}
const innerPontic = ref('')
const ponticArr = ref([])
watch(() => props.pontic, (newVal, oldVal) => {
    if (innerPontic.value === newVal) return
    innerPontic.value = newVal
    ponticArr.value = []
    if (innerToothType.value === toothTypeEnum.桥体) {
        if (!newVal) {
            clearPosArr()
            return
        }
        //桥体字符串拆分牙位数组
        ponticArr.value = newVal.split('|')
        getPosFromPonticArr()
    }
})

const getPosFromPonticArr = () => {
    let posArr = []
    for (let item of ponticArr.value) {
        posArr = posArr.concat(item.split(','))
    }
    pos1Arr.value = posArr.filter(x => x.indexOf('A') >= 0).map(x => x.substring(1))
    pos2Arr.value = posArr.filter(x => x.indexOf('B') >= 0).map(x => x.substring(1))
    pos3Arr.value = posArr.filter(x => x.indexOf('D') >= 0).map(x => x.substring(1))
    pos4Arr.value = posArr.filter(x => x.indexOf('C') >= 0).map(x => x.substring(1))
}

const getToothClass = (pos, posPart) => {
    let fdiItem = getFdiStr(pos, posPart)
    if (innerToothType.value === toothTypeEnum.缺失) {
        return 'checkbox-danger'
    } else if (innerToothType.value === toothTypeEnum.桥体) {
        if (isBegin(pos, posPart)) {
            return 'checkbox-success-right'
        } else if (isEnd(pos, posPart)) {
            return 'checkbox-success-left'
        } else if (fdiItem === beginPontic.value) {
            return 'checkbox-success-tmp'
        } else if (isPontic(fdiItem)) {
            return 'checkbox-success'
        }
    } else {
        return ''
    }
}
const isBegin = (pos, posPart) => {
    let fdiItem = getFdiStr(pos, posPart)
    return ponticArr.value.length && ponticArr.value.some(x => x.indexOf(fdiItem) === 0)
}
const isEnd = (pos, posPart) => {
    let fdiItem = getFdiStr(pos, posPart)
    return ponticArr.value.length && ponticArr.value.some(x => x.indexOf(fdiItem) === (x.length - 2))
}
const isPontic = fdiItem => {
    return ponticArr.value.length && ponticArr.value.some(x => x.indexOf(fdiItem) >= 0)
}

const pos1Arr = ref([])
const changePosFromParent = (val, posPart) => {
    if (posPart === 1) {
        pos1Arr.value = !!val ? val.split('') : []
    } else if (posPart === 2) {
        pos2Arr.value = !!val ? val.split('') : []
    } else if (posPart === 3) {
        pos3Arr.value = !!val ? val.split('') : []
    } else if (posPart === 4) {
        pos4Arr.value = !!val ? val.split('') : []
    }
}
watch(() => props.pos1, (newVal, oldVal) => {
    if (innerToothType.value === toothTypeEnum.单冠) changePosFromParent(newVal, 1)
})
watch(() => props.missing1, (newVal, oldVal) => {
    if (innerToothType.value === toothTypeEnum.缺失) changePosFromParent(newVal, 1)
})
const pos2Arr = ref([])
watch(() => props.pos2, (newVal, oldVal) => {
    if (innerToothType.value === toothTypeEnum.单冠) changePosFromParent(newVal, 2)
})
watch(() => props.missing2, (newVal, oldVal) => {
    if (innerToothType.value === toothTypeEnum.缺失) changePosFromParent(newVal, 2)
})
const pos3Arr = ref([])
watch(() => props.pos3, (newVal, oldVal) => {
    if (innerToothType.value === toothTypeEnum.单冠) changePosFromParent(newVal, 3)
})
watch(() => props.missing3, (newVal, oldVal) => {
    if (innerToothType.value === toothTypeEnum.缺失) changePosFromParent(newVal, 3)
})
const pos4Arr = ref([])
watch(() => props.pos4, (newVal, oldVal) => {
    if (innerToothType.value === toothTypeEnum.单冠) changePosFromParent(newVal, 4)
})
watch(() => props.missing4, (newVal, oldVal) => {
    if (innerToothType.value === toothTypeEnum.缺失) changePosFromParent(newVal, 4)
})
onMounted(() => {
    innerUpperJaw.value = props.upperJaw
    innerLowerJaw.value = props.lowerJaw
    innerToothType.value = props.toothType
    innerPontic.value = props.pontic
    if (innerToothType.value === toothTypeEnum.桥体) {
        pos1Arr.value = props.missing1 ? props.missing1.split('') : []
        pos2Arr.value = props.missing2 ? props.missing2.split('') : []
        pos3Arr.value = props.missing3 ? props.missing3.split('') : []
        pos4Arr.value = props.missing4 ? props.missing4.split('') : []
    } else if (innerToothType.value === toothTypeEnum.单冠) {
        pos1Arr.value = props.pos1 ? props.pos1.split('') : []
        pos2Arr.value = props.pos2 ? props.pos2.split('') : []
        pos3Arr.value = props.pos3 ? props.pos3.split('') : []
        pos4Arr.value = props.pos4 ? props.pos4.split('') : []
    }
})

const increaseArr = ref(['1', '2', '3', '4', '5', '6', '7', '8'])
const decreaseArr = ref(['8', '7', '6', '5', '4', '3', '2', '1'])
const changePos = (pos, posPart) => {
    if (innerToothType.value === toothTypeEnum.单冠) {
        if (posPart === 1) {
            emits('update:pos1', pos1Arr.value.sort().join(''))
        } else if (posPart === 2) {
            emits('update:pos2', pos2Arr.value.sort().join(''))
        } else if (posPart === 3) {
            emits('update:pos3', pos3Arr.value.sort().join(''))
        } else if (posPart === 4) {
            emits('update:pos4', pos4Arr.value.sort().join(''))
        }
    } else if (innerToothType.value === toothTypeEnum.缺失) {
        if (posPart === 1) {
            emits('update:missing1', pos1Arr.value.sort().join(''))
        } else if (posPart === 2) {
            emits('update:missing2', pos2Arr.value.sort().join(''))
        } else if (posPart === 3) {
            emits('update:missing3', pos3Arr.value.sort().join(''))
        } else if (posPart === 4) {
            emits('update:missing4', pos4Arr.value.sort().join(''))
        }
    } else if (innerToothType.value === toothTypeEnum.桥体) {
        changePontic(pos, posPart)
    }
    changeToothNo()
}

const changeUpperJaw = () => {
    emits('update:upperJaw', innerUpperJaw.value)
    changeToothNo()
}
const changeLowerJaw = () => {
    emits('update:lowerJaw', innerLowerJaw.value)
    changeToothNo()
}
const changeToothType = () => {
    if (innerToothType.value === toothTypeEnum.桥体) {
        beginPontic.value = ''
        innerPontic.value = ''
        ponticArr.value = []
        clearPosArr()
    } else {
        beginPontic.value = ''
        innerPontic.value = ''
        ponticArr.value = []
        clearPosArr()
    }
    changeToothNo()
    emits('update:toothType', innerToothType.value)
}
/**
 * 修改产品数量
 */
const changeToothNo = () => {
    let toothNum = 0
    if (innerToothType.value === toothTypeEnum.单冠) {
        if (innerUpperJaw.value || innerLowerJaw.value) {
            toothNum = innerUpperJaw.value + innerLowerJaw.value
        } else {
            toothNum = pos1Arr.value.length + pos2Arr.value.length + pos3Arr.value.length + pos4Arr.value.length
        }
    } else if (innerToothType.value === toothTypeEnum.桥体) {
        if (innerUpperJaw.value || innerLowerJaw.value) {
            toothNum = innerUpperJaw.value + innerLowerJaw.value
        } else {
            toothNum = pos1Arr.value.length + pos2Arr.value.length + pos3Arr.value.length + pos4Arr.value.length
        }
    } else if (innerToothType.value === toothTypeEnum.缺失) {
        if (innerUpperJaw.value || innerLowerJaw.value) {
            toothNum = innerUpperJaw.value + innerLowerJaw.value
        } else {
            toothNum = pos1Arr.value.length + pos2Arr.value.length + pos3Arr.value.length + pos4Arr.value.length
        }
    }
    emits('changeTooth', toothNum)
}
const beginPontic = ref('')
const changePontic = (pos, posPart) => {
    let fdiItem = getFdiStr(pos, posPart)
    // 已有包含该桥体 则取消
    if (ponticArr.value.length) {
        let index = ponticArr.value.findIndex(x => x.indexOf(fdiItem) >= 0)
        if (index >= 0) {
            ponticArr.value.splice(index, 1)
            innerPontic.value = ponticArr.value.join('|')
            emits('update:pontic', innerPontic.value)
            beginPontic.value = ''
            getPosFromPonticArr()
            return
        }
    }
    // 选择桥体结束
    if (beginPontic.value) {
        if (beginPontic.value === fdiItem) {
            beginPontic.value = ''
            return
        }
        if (isUpper(beginPontic.value) && isLower(fdiItem)) {
            return
        }
        if (isLower(beginPontic.value) && isUpper(fdiItem)) {
            return
        }
        let res = getBetween(beginPontic.value, fdiItem)
        for (let item of res) {
            //删除包含同样牙的桥体
            let index = ponticArr.value.findIndex(x => x.indexOf(item) >= 0)
            if (index >= 0) {
                ponticArr.value.splice(index, 1)
            }
        }
        ponticArr.value.push(res.join(','))
        innerPontic.value = ponticArr.value.join('|')
        emits('update:pontic', innerPontic.value)
        beginPontic.value = ''
        getPosFromPonticArr()
        return
    }
    // 选择桥体开始
    if (!beginPontic.value) {
        beginPontic.value = fdiItem
    }
}
</script>

<style lang="scss">
.tooth-container {
    width: 400px;
}

.border-bottom-dash {
    border-bottom: 1px dashed #777;
}

.border-right-dash {
    border-right: 1px dashed #777;
}

.tooth-part {
    padding: 3px 0;
}

.el-checkbox-button--small .el-checkbox-button__inner {
    padding: 5px 8px!important;
}

.el-checkbox-button.is-checked.checkbox-success-tmp .el-checkbox-button__inner {
    color: var(--el-color-success);
    background-color: var(--el-color-white);
    border-color: var(--el-color-success) !important;
    box-shadow: -1px 0 0 0 var(--el-color-success) !important;
}

.el-checkbox-button.is-checked.checkbox-success-left .el-checkbox-button__inner {
    color: var(--el-color-danger);
    background-image: linear-gradient(to right, var(--el-color-success) 50%, white 50%);
    border-color: var(--el-color-success) !important;
    box-shadow: -1px 0 0 0 var(--el-color-success) !important;
}

.el-checkbox-button.is-checked.checkbox-success-right .el-checkbox-button__inner {
    color: var(--el-color-danger);
    background-image: linear-gradient(to left, var(--el-color-success) 50%, white 50%);
    border-color: var(--el-color-success) !important;
    box-shadow: -1px 0 0 0 var(--el-color-success) !important;
}

.el-checkbox-button.is-checked.checkbox-success .el-checkbox-button__inner {
    color: var(--el-color-danger) !important;
    background-color: var(--el-color-success) !important;
    border-color: var(--el-color-success) !important;
    box-shadow: -1px 0 0 0 var(--el-color-success) !important;
}

</style>