import {reactive, ref, inject} from 'vue'
import {
    caseFileUpload,
    casePicUpload,
    fileChunkUpload,
    fileFinishUpload,
    fileGetChunks
} from "@/api/system";
import {ElMessage} from "element-plus";
import SparkMD5 from 'spark-md5'

const picPathPrefix = '/api/factory/file/get?t='

function useUploadPic(picType) {
    const uploadResult = ref(false)
    const picToken = ref('')
    const picId = ref('')
    const fileName = ref('')
    const uploadFileList = ref([])
    const uploadPic = async param => {
        uploadResult.value = false
        picToken.value = ''
        const formData = new FormData()
        formData.append("file", param.file)
        const config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        };
        try {
            let res = null
            if (picType === 'casePic') {
                res = await casePicUpload(formData, config)
            }
            if (res.code === 0) {
                uploadResult.value = true
                picToken.value = res.data.picToken
                picId.value = res.data.picId
                fileName.value = res.data.orgFileName
                uploadFileList.value.push({
                    fileToken: res.data.picToken,
                    fileId: res.data.picId,
                    fileName: res.data.orgFileName,
                    id: 0,
                })
            } else {
                ElMessage.error(res.message)
            }
        } catch (e) {
            ElMessage.error('上传失败，请检查网络或联系客服')
        }
    }
    return {
        uploadResult,
        picToken,
        picId,
        fileName,
        uploadFileList,
        uploadPic
    }
}

const beforePicUpload = file => {
    // 文件类型限制为jpg/png/jpeg
    const fileTypeCheck = file.type.indexOf('jpg') >= 0 || file.type.indexOf('jpeg') >= 0 || file.type.indexOf('png') >= 0
    if (!fileTypeCheck) {
        ElMessage.error("上传文件只能是jpg、jpeg、png格式");
    }
    // 文件大小限制为10M
    const fileLimit = file.size / 1024 / 1024 < 20;
    if (!fileLimit) {
        ElMessage.error("上传文件大小不超过20M！");
    }
    return fileLimit && fileTypeCheck
}

const beforeExcelUpload = file => {
    // 文件类型限制为jpg/png/jpeg
    const fileTypeCheck = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/vnd.ms-excel'
    if (!fileTypeCheck) {
        ElMessage.error("上传文件只能是xls、xlsx格式");
    }
    // 文件大小限制为10M
    const fileLimit = file.size / 1024 / 1024 < 10;
    if (!fileLimit) {
        ElMessage.error("上传文件大小不超过10M！");
    }
    return fileLimit && fileTypeCheck
}

// 下载文件，自定义文件名称
function useDownload() {
    const downFileLoading = ref(false)

    function downFile(url, fileName) {
        downFileLoading.value = true
        const x = new XMLHttpRequest()
        x.open('GET', url, true)
        x.responseType = 'blob'
        x.onload = function () {
            const url = window.URL.createObjectURL(x.response)
            const a = document.createElement('a')
            a.href = url
            a.download = fileName
            a.click()
        }
        x.send()
        x.onreadystatechange = function () {
            if (x.readyState === 4) {
                downFileLoading.value = false
            }
        }
    }

    return {
        downFileLoading,
        downFile
    }
}

function useUploadBigFile() {
    function changeBuffer(file) {
        return new Promise(resolve => {
            let fileReader = new FileReader()
            fileReader.readAsArrayBuffer(file);
            fileReader.onload = ev => {
                let buffer = ev.target.result,
                    spark = new SparkMD5.ArrayBuffer(),
                    hash,
                    suffix;
                spark.append(buffer)
                // 拿到文件的hash值
                hash = spark.end()
                // 匹配文件后缀名
                suffix = /\.([a-zA-Z0-9]+)$/.exec(file.name)[1];
                resolve({
                    buffer,
                    hash,
                    suffix,
                    mimeType: file.type,
                    orgFileName: file.name
                })
            }
        })
    }

    const uploadBigFileLoading = ref(false)//上传loading
    const progress = ref(0)//上传进度
    const bigFileList = ref([])
    const resultBigFile = reactive({
        fileId: null,
        fileToken: '',
        orgFileName: ''
    })
    const initBigFile = () => {
        bigFileList.value = []
        uploadBigFileLoading.value = false
        progress.value = 0
        resultBigFile.fileId = null
        resultBigFile.fileToken = ''
        resultBigFile.orgFileName = ''
    }

    async function uploadBigFile(param) {
        uploadBigFileLoading.value = true
        progress.value = 0
        let file = param.file
        const config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        };
        // 获取文件的HASH
        let alreadyUploadChunks = [], // 当前已经上传的切片
            {
                hash,
                suffix,
                mimeType,
                orgFileName
            } = await changeBuffer(file);
        let chunksRes = await fileGetChunks({hash: hash})
        alreadyUploadChunks = chunksRes ? chunksRes : []
        // 实现文件切片处理 「固定数量 或者 固定大小」
        let max = 5 * 1024 * 1024, // 切片大小
            count = Math.ceil(file.size / max), // 切片总数
            index = 0,   // 当前上传的切片索引值
            chunks = []; // 存放切片的数组
        if (count > 100) {
            max = file.size / 100;
            count = 100;
        }
        // 存放切片，注意此处的切片名称，hash+index+suffix
        while (index < count) {
            chunks.push({
                file: file.slice(index * max, (index + 1) * max),
                fileName: `${hash}_${index + 1}.${suffix}`
            });
            index++;
        }
        for (let i = 0; i < count; i++) {
            let chunk = chunks[i]
            if (alreadyUploadChunks.length && alreadyUploadChunks.includes(chunk.fileName)) {
                progress.value = ((i + 1) / count) * 100
                if (i === (count - 1)) {
                    // 最后一个切片上传过
                    await mergeFile(hash, count)
                    uploadBigFileLoading.value = false
                }
                continue
            }
            let formData = new FormData()
            formData.append("file", chunk.file)
            formData.append("filename", chunk.fileName)
            formData.append("orgFileName", orgFileName)
            formData.append("orgMimeType", mimeType)
            let res = await fileChunkUpload(formData, config)
            if (res.code === 0) {
                progress.value = ((i + 1) / count) * 100
                if (i === (count - 1)) {
                    // 最后一个切片上传完成
                    await mergeFile(hash, count)
                    uploadBigFileLoading.value = false
                }
            } else {
                ElMessage.error(res.message)
                uploadBigFileLoading.value = false
                return
            }
        }
    }

    async function mergeFile(hash, count) {
        let param = {
            hash: hash,
        }
        let res = await fileFinishUpload(param)
        resultBigFile.fileId = res.picId
        resultBigFile.fileToken = res.picToken
        resultBigFile.orgFileName = res.orgFileName
        bigFileList.value.push({
            id: 0,
            fileId: res.picId,
            fileToken: res.picToken,
            orgFileName: res.orgFileName,
        })
    }

    // 大文件上传 和已有文件对比检查同名文件
    function beforeBigFileUpload(file) {
        if (bigFileList.value && bigFileList.value.length) {
            if (bigFileList.value.map(x => x.orgFileName).includes(file.name)) {
                ElMessage.error('不能上传同名文件，请检查')
                return false
            }
        }
        return true
    }

    return {
        uploadBigFile,
        uploadBigFileLoading,
        progress,
        bigFileList,
        initBigFile,
        resultBigFile,
        beforeBigFileUpload,
    }
}


export {
    picPathPrefix,
    useUploadPic,
    beforePicUpload,
    beforeExcelUpload,
    useDownload,
    useUploadBigFile
}
