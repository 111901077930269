import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

// 一次性解决时区和日期格式问题
import moment from 'moment'
Date.prototype.toISOString = function(){
    return moment(this).format('YYYY-MM-DD HH:mm:ss')
}
const app = createApp(App)

app.use(ElementPlus, {
    locale: zhCn,
    size: 'default'
})
app.use(store)
app.use(router)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

//复制对象字段
app.provide('setFields', (targetObj, val) => {
    for (let field in targetObj) {
        if (val[field] !== undefined) targetObj[field] = val[field]
    }
})
//清空对象字段
app.provide('initFields', (targetObj) => {
    for (let field in targetObj) {
        if (field === 'id' || field.indexOf('Id') >= 0 || field === 'points' || field.indexOf('Level') >= 0 || field === 'pageIndex' || field === 'isCollect') {
            targetObj[field] = 0
        } else if (field.indexOf('List') >= 0) {
            targetObj[field] = []
        } else {
            targetObj[field] = ''
        }
    }
})

//自定义全局指令v-only-number 让输入框只能输入数字
import onlyNumber from './directive/onlyNumberDirective'
app.directive(onlyNumber.name, onlyNumber.directive)
import onlyInt from './directive/onlyIntDirective'
app.directive(onlyInt.name, onlyInt.directive)
// 跳转路由
app.provide('toPage', (uri, checkResult = true) => {
    if (checkResult) {
        router.push(uri)
    }
})
//全局组件
import tableTooth from './components/tableTooth'
app.component('table-tooth',tableTooth)
import tableToothMini from './components/tableToothMini'
app.component('table-tooth-mini',tableToothMini)
import productSelect from './components/productSelect'
app.component('product-select',productSelect)

app.mount('#app')