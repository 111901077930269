import {
    userLogin,
    userLoginHospital,
    userResetPassword,
    webStoreGet,
    loginLogGetPage,
    userRefreshToken,
    userLoginHospitalToken,
} from "@/api/system";
import {ref, reactive, inject, toRaw} from "vue";
import {ElMessage} from "element-plus";
import {useStore} from 'vuex'
import {cnPhoneNoReg} from '@/util/validator'
import {sha256_digest} from "@/util/sha"
import {appType} from "@/util/enum";
import moment from 'moment'

function useResetPassword() {
    const resetPasswordParam = reactive({
        password: '',
        passwordAgain: ''
    })
    const resetPasswordRef = ref()
    const resetPasswordRules = reactive({
        password: [
            {required: true, message: '请输入密码', trigger: 'blur'},
        ],
        passwordAgain: [
            {required: true, message: '请再次输入密码', trigger: 'blur'},
        ]
    })
    const resetPasswordLoading = ref(false)
    const resetPasswordResult = ref(false)
    const resetPassword = async () => {
        resetPasswordResult.value = false
        if (resetPasswordLoading.value) return
        await resetPasswordRef.value.validate(async (valid, fields) => {
            if (!valid) {
                return
            }
            if (resetPasswordParam.password !== resetPasswordParam.passwordAgain) {
                ElMessage.error('两次输入的密码不一致，请检查')
                return
            }
            resetPasswordLoading.value = true
            let param = {
                id: resetPasswordParam.id,
                password: sha256_digest(resetPasswordParam.password)
            }
            resetPasswordResult.value = await userResetPassword(param)
            resetPasswordLoading.value = false
        })
    }
    return {
        resetPasswordParam,
        resetPasswordRef,
        resetPasswordRules,
        resetPasswordLoading,
        resetPasswordResult,
        resetPassword
    }
}

function useLogin() {
    const store = useStore()
    const toPage = inject('toPage')
    const user = reactive({
        userName: '',
        password: ''
    })
    const loginLoading = ref(false)
    const doctorList = ref([])
    const doctorId = ref(0)
    const doctorVisible = ref(false)
    const login = async () => {
        if (loginLoading.value) return
        if (!user.userName) {
            ElMessage.error('请输入手机号')
            return
        }
        if (!user.password) {
            ElMessage.error('请输入密码')
            return
        }
        loginLoading.value = true
        let param = {
            userName: user.userName,
            password: sha256_digest(user.password)
        }
        try {
            localStorage.setItem('doctorUserName', user.userName)
            let res = await userLogin(param)
            if (res.code === 0) {
                doctorList.value = res.data.list
                doctorId.value = doctorList.value[0].facDoctorId
                if (doctorList.value.length === 1) {
                    await loginHospital()
                } else {
                    doctorVisible.value = true
                }
            } else {
                ElMessage.error(res.message)
            }
        } catch (e) {
            console.log(e)
            ElMessage.error('请求错误，请检查网络或者联系客服')
        }
        loginLoading.value = false
    }
    const loginHospitalLoading = ref(false)
    const loginHospital = async () => {
        if (!doctorId.value) {
            ElMessage.error('请选择工厂医院医生')
            return
        }
        if (loginHospitalLoading.value) return
        let doctor = doctorList.value.find(x => x.facDoctorId === doctorId.value);
        if (!doctor) {
            ElMessage.error('请选择工厂医院医生')
            return
        }
        loginHospitalLoading.value = true
        store.commit('setCurrentDoctor', doctor)
        store.commit('setUserName', user.userName)
        store.commit('setFactoryId', doctor.factoryId)
        let param = {
            userName: user.userName,
            password: sha256_digest(user.password),
            factoryId: doctor.factoryId,
            factoryName: doctor.factoryName,
            facHospitalId: doctor.facHospitalId,
            facHospitalName: doctor.facHospitalName,
            facDoctorId: doctor.facDoctorId,
            facDoctorName: doctor.facDoctorName,
            userAppType: appType.工厂医生下单平台,
        }
        try {
            let res = await userLoginHospital(param)
            if (res.code === 0) {
                ElMessage.success('登录成功')
                store.commit('setToken', res.data.token)
                store.commit('setExpireTime', moment(res.data.expireTime).format('yyyy-MM-DD HH:mm:ss'))
                store.commit('setRefreshToken', res.data.refreshToken)
                store.commit('setRefreshTokenExpireTime', moment(res.data.refreshTokenExpireTime).format('yyyy-MM-DD HH:mm:ss'))
                toPage('/admin/welcome')
            } else {
                ElMessage.error(res.message)
            }
        } catch (e) {
            console.log(e)
            ElMessage.error('请求错误，请检查网络或者联系客服')
        }
        loginHospitalLoading.value = false
    }
    const loginHospitalByToken = async () => {
        if (loginHospitalLoading.value) return
        if (!store.state.token) {
            return
        }
        loginHospitalLoading.value = true
        let data = await userLoginHospitalToken({token: store.state.token})
        if (data) {
            localStorage.setItem('doctorUserName', data.userName)
            store.commit('setCurrentDoctor', data)
            store.commit('setUserName', data.userName)
            store.commit('setFactoryId', data.factoryId)
            toPage('/admin/welcome')
        }
        loginHospitalLoading.value = false
    }
    return {
        user,
        login,
        loginLoading,
        doctorList,
        doctorId,
        doctorVisible,
        loginHospitalLoading,
        loginHospital,
        loginHospitalByToken,
    }
}

function useLogout() {
    const store = useStore()
    const toPage = inject('toPage')
    const logOut = () => {
        store.commit('setToken', '')
        store.commit('setExpireTime', '')
        store.commit('setRefreshToken', '')
        store.commit('setRefreshTokenExpireTime', '')
        sessionStorage.clear()
        toPage('/')
    }
    return {
        logOut
    }
}

function useGetWebStore() {
    const store = useStore()
    const getWebStoreLoading = ref(false)
    const getWebStore = async () => {
        if (getWebStoreLoading.value) return
        getWebStoreLoading.value = true
        let res = await webStoreGet()
        if (res) {
            store.commit('setToothColorList', res.toothColorList)
            store.commit('setProductDetails', res.productList)
            let productCount = store.state.productCount
            let factoryId = store.state.factoryId ? parseInt(store.state.factoryId) : 0
            let productSimpleList = res.productList.map(x => {
                let product = productCount.find(a => a.productId === x.id && a.factoryId === factoryId)
                let count = product ? product.count : 0
                return {
                    id: x.id,
                    name: x.name,
                    pinyin: x.pinyin,
                    code: x.productNo,
                    extra1: x.productUnit,
                    extra2: x.testRegNo,
                    extra3: '',
                    count: count,
                    parentId: null,
                    typePath: x.typePath,
                    productTypeName: x.productTypeName,
                }
            })
            store.commit('setProductList', productSimpleList)
            store.commit('setDoctorList', res.doctorList)
            store.commit('setDataValueList', res.dataValueList)
            store.commit('setHospital', res.hospital)
            store.commit('setPlantSystemList', res.plantSystemList ? res.plantSystemList : [])
            store.commit('setPlantRetentionList', res.plantRetentionList ? res.plantRetentionList : [])
            store.commit('setPrivatePriceList', res.privatePriceList ? res.privatePriceList : [])
            store.commit('setProductType', res.productTypeList ? res.productTypeList : [])
        }
        getWebStoreLoading.value = false
    }
    return {
        getWebStore,
        getWebStoreLoading
    }
}

// 用户操作记录翻页
function useGetPageLoginLog() {
    const getPageLoginLogParam = reactive({
        beginDate: '',
        endDate: '',
        pageIndex: 1,
        pageSize: 20,
    })
    const getPageLoginLogParamEmpty = JSON.parse(JSON.stringify(toRaw(getPageLoginLogParam)))
    const getPageLoginLogResult = reactive({
        totalCount: 0,
        totalPages: 0,
    })
    const loginLogPage = ref([])
    const initGetPageLoginLogParam = () => {
        Object.assign(getPageLoginLogParam, getPageLoginLogParamEmpty)
    }
    const getPageLoginLogLoading = ref(false)
    const getPageLoginLog = async () => {
        if (getPageLoginLogLoading.value) return
        getPageLoginLogLoading.value = true
        let data = await loginLogGetPage(getPageLoginLogParam)
        if (data && data.list && data.list.length) {
            loginLogPage.value = data.list
            getPageLoginLogResult.totalPages = Math.ceil(data.totalCount / getPageLoginLogParam.pageSize)
            getPageLoginLogResult.totalCount = data.totalCount
        } else {
            loginLogPage.value = []
            getPageLoginLogResult.totalPages = 0
            getPageLoginLogResult.totalCount = 0
        }
        getPageLoginLogLoading.value = false
    }
    return {
        getPageLoginLogParam,
        getPageLoginLogResult,
        loginLogPage,
        initGetPageLoginLogParam,
        getPageLoginLogLoading,
        getPageLoginLog
    }
}

function useRefreshToken() {
    const refreshTokenLoading = ref(false)
    const store = useStore()
    const refreshToken = async () => {
        if (refreshTokenLoading.value) return
        refreshTokenLoading.value = true
        let token = store.state.token
        let expireTime = store.state.expireTime
        let refreshToken = store.state.refreshToken
        let refreshTokenExpireTime = store.state.refreshTokenExpireTime
        let result = false
        if (token && expireTime && moment(expireTime).isAfter(moment())) {
            // 有token且还未过期
            result = true
        } else if (refreshToken && refreshTokenExpireTime && moment(expireTime).isAfter(moment())) {
            // 有refreshToken且还未过期
            let data = await userRefreshToken({refreshToken: refreshToken})
            if (data) {
                store.commit('setToken', data.token)
                store.commit('setExpireTime', moment(data.expireTime).format('yyyy-MM-DD HH:mm:ss'))
                result = true
            }
        }
        refreshTokenLoading.value = false
        return result
    }
    return {
        refreshTokenLoading,
        refreshToken
    }
}

export {
    useLogin,
    useResetPassword,
    useLogout,
    useGetWebStore,
    useGetPageLoginLog,
    useRefreshToken,
}