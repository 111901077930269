import moment from 'moment'
import {ref} from "vue";
// 本文件用来存放各种格式化数据的方法

// 用来格式化el-table中的日期时间格式
function datetimeFormat(row, col, val, index) {
    if (val) {
        return moment(val).format('yyyy-MM-DD HH:mm:ss')
    }
    return val
}

// 用来格式化el-table中的日期格式
function dateFormat(row, col, val, index) {
    if (val) {
        return moment(val).format('yyyy-MM-DD')
    }
    return val
}

// 用来格式化el-table中的是/否
function yesNoFormat(row, col, val, index) {
    if (val === 1) {
        return "是"
    } else if (val === 0) {
        return "否"
    }
    return ''
}

// 格式化表格的枚举值
// 使用方法示例    :formatter="(r,c,v)=>enumTypeFormatter(v,trainType)"
function enumTypeFormatter(val, enumList) {
    let result = enumList.find(x => x.id === val)
    if (result) {
        return result.name
    } else {
        return ''
    }
}

// 格式化daterange的时间
const dateRangeDefaultTime = ref([
    new Date(2000, 1, 1, 0, 0, 0),
    new Date(2000, 2, 1, 23, 59, 59),
])

// CommonListRes的下拉列表（中文、编号、拼音首字母）筛选方法
const commonListFilter = val => {
    return x => {
        return (x.name && x.name.indexOf(val) >= 0) || (x.code && x.code.indexOf(val) >= 0) || (x.pinyin && x.pinyin.indexOf(val.toUpperCase()) >= 0)
    }
}
import {nullProductNo} from "@/util/constant";
// CommonListRes的下拉列表（中文、编号、拼音首字母）默认显示数量
const commonListDefaultFilter = (x, index) => {
    //员工下拉列表最多显示20个
    return x.code===nullProductNo || index < 20
}
// 列表项中如果作废的 显示为红色背景（isValid字段判断）
const tableRowClass = ({row, index}) => {
    if (!row.isValid) {
        return 'danger-row'
    } else {
        return ''
    }
}

export {
    datetimeFormat,
    dateFormat,
    yesNoFormat,
    dateRangeDefaultTime,
    enumTypeFormatter,
    commonListFilter,
    commonListDefaultFilter,
    tableRowClass,
}