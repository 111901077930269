// 未指定产品编号
export const nullProductNo='P0'
// 通用分页
export const pageSizeList = [10, 20, 30, 40]
export const defaultPageSize = 10
//部门树
export const deptTreeProps = {
    checkStrictly: true,
    children: 'children',
    label: 'deptName',
    value: 'id',
    emitPath: false
}
//通用树
export const commonTreeProps = {
    children: 'children',
    label: 'name',
    value: 'id',
    emitPath: false
}
//允许选择父节点的搜索树
export const commonSearchTree = {
    checkStrictly: true,
    children: 'children',
    label: 'name',
    value: 'id',
    emitPath: false
}
//打印左右边距
export const printMinLeft = 5
//打印A4宽度px
export const a4Width = 595
//A4的panel设置
export const a4Panel = {width: 210, height: 297, paperFooter: 340, paperHeader: 10}
