<template>
    <div class="view-container">
        <div class="view-list flex-row">
            <div class="view-list-left">
                <el-form label-width="90px" :model="getSimplePageCaseParam">
                    <el-form-item label-width="10px">
                        <el-row :gutter="5">
                            <el-col :span="12">
                                <el-input placeholder="搜索订单号" v-model.trim="getSimplePageCaseParam.caseNo"
                                          @keydown.enter="searchCase"
                                          maxlength="30"></el-input>
                            </el-col>
                            <el-col :span="12">
                                <el-input placeholder="搜索患者" v-model.trim="getSimplePageCaseParam.patientName"
                                          @keydown.enter="searchCase"
                                          maxlength="30"></el-input>
                            </el-col>
                        </el-row>
                    </el-form-item>
                    <el-form-item label-width="10px">
                        <el-row :gutter="5">
                            <el-col :span="12">
                                <el-input placeholder="搜索客户单号" v-model.trim="getSimplePageCaseParam.hospitalCaseNo"
                                          @keydown.enter="searchCase"
                                          maxlength="30"></el-input>
                            </el-col>
                            <el-col :span="12">
                                <el-input placeholder="搜索关联订单号" v-model.trim="getSimplePageCaseParam.sourceCaseNo"
                                          @keydown.enter="searchCase"
                                          maxlength="30"></el-input>
                            </el-col>
                        </el-row>
                    </el-form-item>
                    <el-form-item label="入件日期">
                        <el-date-picker v-model="searchDateRange" type="daterange" size="default"
                                        value-format="YYYY-MM-DD HH:mm:ss"
                                        :default-time="dateRangeDefaultTime" start-placeholder="开始日期"
                                        end-placeholder="结束日期"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="出件日期">
                        <el-date-picker v-model="searchOutDateRange" type="daterange" size="default"
                                        value-format="YYYY-MM-DD HH:mm:ss"
                                        :default-time="dateRangeDefaultTime" start-placeholder="开始日期"
                                        end-placeholder="结束日期"></el-date-picker>
                    </el-form-item>
                    <el-form-item label-width="15px">
                        <el-checkbox v-model="onlyAudit">只看待审</el-checkbox>
                        <el-button type="primary" @click="searchCase"
                                   :loading="getSimplePageCaseLoading">搜索
                        </el-button>
                        <el-button @click="initGetSimplePageCase">重置</el-button>
                        <el-button type="success" @click="newCase">新增</el-button>
                    </el-form-item>
                </el-form>
                <el-table :data="casePage" :show-header="false" v-loading="getSimplePageCaseLoading || caseGetLoading"
                          :highlight-current-row="true" @row-click="chooseRow" ref="casePageRef"
                          :row-class-name="tableRowClass">
                    <el-table-column>
                        <template #default="scope">
                            <div style="display: flex;justify-content:space-between;">
                                <b style="flex-basis: 120px;">{{ scope.row.caseNo }}</b>
                                <div class="flex-row" style="flex-basis: 175px;justify-content:space-between;">
                                    <div :class="{'text-danger':scope.row.isPrivate===1}">
                                        {{ scope.row.facDoctorName }}
                                        &nbsp;&nbsp;
                                        <span v-if="scope.row.auditState===auditStateEnum.待审核"
                                              class="text-warning">待审</span>
                                        <span v-if="scope.row.auditState===auditStateEnum.不通过"
                                              class="text-danger">拒绝</span>
                                    </div>
                                    <div class="text-primary">
                                        {{ scope.row.patientName }}
                                    </div>
                                    <el-tag size="small" v-if="scope.row.isUrgent===1" type="danger">急</el-tag>
                                    <el-tag size="small" v-if="scope.row.isTry===1" type="warning">戴</el-tag>
                                </div>
                            </div>
                            <div style="display: flex;justify-content:space-between;">
                                <span class="text-success">{{ scope.row.facHospitalName }}</span>&nbsp;
                                <div>
                                    <el-tag size="small" v-if="scope.row.caseType===caseTypeEnum.正常">
                                        {{ getCaseTypeName(scope.row.caseType) }}
                                    </el-tag>
                                    <el-tag size="small" v-else type="danger">
                                        {{ getCaseTypeName(scope.row.caseType) }}
                                    </el-tag>

                                    <el-tag size="small" v-if="scope.row.caseState>=caseStateEnum.已发货" type="success">
                                        {{ getCaseStateName(scope.row.caseState) }}
                                    </el-tag>
                                    <el-tag size="small" v-else>
                                        {{ getCaseStateName(scope.row.caseState) }}
                                    </el-tag>

                                    <span>{{ moment(scope.row.inDate).format('yyyy/MM/DD') }}</span>
                                </div>
                            </div>

                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                    v-show="!!casePage.length"
                    v-model:currentPage="getSimplePageCaseParam.pageIndex"
                    v-model:page-size="getSimplePageCaseParam.pageSize"
                    :disabled="!getSimplePageCaseResult.totalCount"
                    layout="total, prev, pager, next"
                    :pager-count="5"
                    :total="getSimplePageCaseResult.totalCount"
                    @size-change="searchPage"
                    @current-change="searchPage"
                />
            </div>
            <div class="view-list-right flex-grow">
                <el-tabs type="border-card" style="min-height: 100%;" v-model="curTab" @tab-change="changeTab">
                    <el-tab-pane :label="facCase.id?'修改订单':'新增订单'" name="add">
                        <el-scrollbar style="width: 100%;padding-bottom: 10px;">
                            <el-tag v-if="facCase.auditState===auditStateEnum.不通过" type="danger"
                                    style="margin-bottom:15px;font-size: 16px;">审核不通过原因：{{ facCase.rejectReason }}
                            </el-tag>
                            <el-tag v-if="facCase.caseState>=caseStateEnum.入件" type="danger"
                                    style="margin-bottom:15px;font-size: 16px;">
                                订单已入件，无法再修改
                            </el-tag>
                            <el-tag type="danger" v-if="facCase.isValid===0"
                                    style="margin-bottom:15px;font-size: 16px;">
                                已作废 {{facCase.validReason}}
                            </el-tag>
                            <el-form :model="facCase" label-width="90px" ref='caseFormRef' :rules='caseSaveRules'
                                     @submit.prevent class="compact-form form-width"
                                     v-loading='saveCaseLoading || caseGetLoading'>
                                <el-row :gutter="5">
                                    <el-col :span="6">
                                        <el-form-item label="订单号" prop="caseNo">
                                            <el-input v-model.trim="facCase.caseNo" maxlength="50" class='input-medium'
                                                      :readonly="!!facCase.id"
                                                      ref="caseNoRef"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item label="关联订单号" prop="sourceCaseNo">
                                            <el-input v-model.trim="facCase.sourceCaseNo" maxlength="20"
                                                      @keydown.enter="getCaseInfoBySourceCaseNo"
                                                      class='input-medium'></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-alert title="不填写订单号系统会自动生成，订单号不可修改"></el-alert>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="5">
                                    <el-col :span="12">
                                        <el-form-item label="订单类型" prop="caseType">
                                            <el-radio-group v-model="facCase.caseType">
                                                <el-radio-button v-for="item in caseType" :key="item.id"
                                                                 :label="item.id">
                                                    {{ item.name }}
                                                </el-radio-button>
                                            </el-radio-group>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="3">
                                        <el-form-item label="是否试戴" prop="isTry">
                                            <el-switch v-model="facCase.isTry" :active-value="1"
                                                       :inactive-value="0"></el-switch>

                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="3">
                                        <el-select size="default" v-model="facCase.tryState" filterable
                                                   v-show="!!facCase.isTry"
                                                   class="input-small">
                                            <el-option v-for="item in tryState" :key="item.id" :value="item.id"
                                                       :label="item.name">
                                            </el-option>
                                        </el-select>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="5">
                                    <el-col :span="6">
                                        <el-form-item label="医院" prop="facHospitalId">
                                            {{ currentDoctor.facHospitalName }}
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item label="医生" prop="facDoctorId">
                                            {{ currentDoctor.facDoctorName }}
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item label="销售员" prop="facWorkerId">
                                            {{ storeHospitalDetail.salesmanName }}
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item label="病历号" prop="medicalNo">
                                            <el-input v-model.trim="facCase.medicalNo" maxlength="50"
                                                      class='input-medium'></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="5">
                                    <el-col :span="6">
                                        <el-form-item label="患者姓名" prop="patientName">
                                            <el-input v-model.trim="facCase.patientName" maxlength="50"
                                                      ref="patientNameRef"
                                                      class='input-medium'></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item label="患者年龄" prop="patientAge">
                                            <el-input-number v-model="facCase.patientAge" :min="0"
                                                             size='default'></el-input-number>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item label="患者性别" prop="gender">
                                            <el-radio-group v-model="facCase.gender">
                                                <el-radio v-for="item in genderList" :key="item" :label="item">
                                                    {{ item }}
                                                </el-radio>
                                            </el-radio-group>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item label="患者手机" prop="patientPhoneNo">
                                            <el-input v-model.trim="facCase.patientPhoneNo" maxlength="50"
                                                      class='input-medium'></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="5">
                                    <el-col :span="6">
                                        <el-form-item label="私人单位" prop="isPrivate">
                                            <el-switch v-model="facCase.isPrivate" :active-value="1"
                                                       @change="changeCaseFacDoctor(true)"
                                                       :inactive-value="0"></el-switch>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item label="是否开票" prop="isInvoice">
                                            <el-switch v-model="facCase.isInvoice" :active-value="1"
                                                       :inactive-value="0"></el-switch>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item label="客户订单号" prop="hospitalCaseNo">
                                            <el-input v-model.trim="facCase.hospitalCaseNo" maxlength="20"
                                                      class='input-medium'></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item label="是否加急" prop="isUrgent">
                                            <el-switch v-model="facCase.isUrgent" :active-value="1"
                                                       :inactive-value="0"></el-switch>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="5">
                                    <el-col :span="12">
                                        <el-form-item label="医生备注" prop="doctorRemark">
                                            <el-input v-model.trim="facCase.doctorRemark" maxlength="200"
                                                      class='input-extra'></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item label="外返原因" prop="redoReason">
                                            <el-input v-model.trim="facCase.redoReason" maxlength="200"
                                                      class='input-extra'>
                                                <template #append>
                                                    <el-button :icon="Search" @click="showRedoReason"></el-button>
                                                </template>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="5">
                                    <el-col :span="6">
                                        <el-form-item label="总金额" prop="totalPrice" class="text-danger">
                                            {{ facCase.totalPrice }}
                                            <!--                                            <el-input v-model.number="facCase.totalPrice" maxlength="10" v-only-number-->
                                            <!--                                                      readonly-->
                                            <!--                                                      class='input-medium'></el-input>-->
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item label="总数量">
                                            {{ totalNum }}
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item label="总折扣">
                                            {{ totalDiscount }}
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-form-item label-width="15px">
                                            <el-space>
                                                <span v-show="!!facCase.makeCost">制作费用:{{ facCase.makeCost }}</span>
                                                <span v-show="!!facCase.materialCost">材料费用:{{
                                                        facCase.materialCost
                                                    }}</span>
                                            </el-space>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="5">
                                    <el-col :span="24">
                                        <el-table :data="facCase.productList" :border="true" class="form-width">
                                            <el-table-column label="操作" width="80" class-name="compact-cell">
                                                <template #default="scope">
                                                    <el-button-group  v-show="allowChange">
                                                        <el-button plain :icon="Plus" type="primary" size="small"
                                                                   title="添加产品"
                                                                   @click="newProduct"></el-button>
                                                        <el-popconfirm confirm-button-text="确认" cancel-button-text="取消"
                                                                       title="确认删除吗"
                                                                       @confirm="deleteProduct(scope.row,scope.$index)">
                                                            <template #reference>
                                                                <el-button plain :icon="Delete" type="danger"
                                                                           size="small"></el-button>
                                                            </template>
                                                        </el-popconfirm>
                                                    </el-button-group>
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="产品" min-width="130" class-name="compact-cell" label-class-name="text-danger">
                                                <template #default="scope">
                                                    <product-select :key="scope.$index"
                                                                    v-model:product-id="scope.row.productId"
                                                                    :product-list="storeProductList"
                                                                    @change-product="changeCaseProduct(scope.row)">
                                                    </product-select>
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="牙色" width="90" class-name="compact-cell">
                                                <template #default="scope">
                                                    <el-autocomplete
                                                        v-model.trim="scope.row.color"
                                                        :fetch-suggestions="searchColor"
                                                        value-key="color"
                                                        clearable
                                                    />
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="牙位" width="385" class-name="compact-cell" label-class-name="text-danger">
                                                <template #default="scope">
                                                    <table-tooth-input
                                                        :key="scope.$index"
                                                        v-model:pos1="scope.row.pos1"
                                                        v-model:pos2="scope.row.pos2"
                                                        v-model:pos3="scope.row.pos3"
                                                        v-model:pos4="scope.row.pos4"
                                                        v-model:tooth-type="scope.row.toothType"
                                                        v-model:lower-jaw="scope.row.lowerJaw"
                                                        v-model:upper-jaw="scope.row.upperJaw"
                                                        v-model:pontic="scope.row.pontic"
                                                        v-model:missing1="scope.row.missing1"
                                                        v-model:missing2="scope.row.missing2"
                                                        v-model:missing3="scope.row.missing3"
                                                        v-model:missing4="scope.row.missing4"
                                                        @change-tooth="val=>setProductNum(val,scope.row)"
                                                    />
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="数量" width="70" class-name="compact-cell" label-class-name="text-danger">
                                                <template #default="scope">
                                                    <el-input v-only-int v-model.number="scope.row.num"
                                                              @blur="getTotalPrice"
                                                              maxlength="8"></el-input>
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="单价" width="75">
                                                <template #default="scope">
                                                    {{ scope.row.price }}
                                                    <!--                                                    <el-input v-only-number v-model.number="scope.row.price"-->
                                                    <!--                                                              @blur="getTotalPrice" readonly-->
                                                    <!--                                                              maxlength="8"></el-input>-->
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="折扣" width="75">
                                                <template #default="scope">
                                                    {{ scope.row.discount }}
                                                    {{ scope.row.discountType === 0 ? '%' : '¥' }}
                                                    <!--                                                    <el-input v-only-number v-model.number="scope.row.discount"-->
                                                    <!--                                                              maxlength="8" readonly-->
                                                    <!--                                                              @blur="getTotalPrice">-->
                                                    <!--                                                        <template #append>-->
                                                    <!--                                                            <el-button v-if="scope.row.discountType===0" disabled-->
                                                    <!--                                                                       @click="changeDiscountType(scope.row,1)">%-->
                                                    <!--                                                            </el-button>-->
                                                    <!--                                                            <el-button v-if="scope.row.discountType===1" disabled-->
                                                    <!--                                                                       @click="changeDiscountType(scope.row,0)">¥-->
                                                    <!--                                                            </el-button>-->
                                                    <!--                                                        </template>-->
                                                    <!--                                                    </el-input>-->
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="小计" width="75">
                                                <template #default="scope">
                                                    {{ scope.row.sum }}
                                                    <!--                                                    <el-input v-only-number v-model.trim="scope.row.sum" readonly-->
                                                    <!--                                                              maxlength="8"></el-input>-->
                                                </template>
                                            </el-table-column>

                                        </el-table>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="5">
                                    <el-col :span="8">
                                        <el-form-item label="种植系统" prop="plantSystems" label-width="80px">
                                            <el-select size="default" v-model="plantSystems"
                                                       placeholder="请选择种植系统"
                                                       filterable multiple style="width:100%;"
                                                       :multiple-limit="10">
                                                <el-option v-for="item in plantSystemList" :key="item.id"
                                                           :value="item.name"
                                                           :label="item.name">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                        <el-form-item label="种植固位" prop="plantRetentions" label-width="80px">
                                            <el-select size="default" v-model="plantRetentions"
                                                       placeholder="请选择种植固位"
                                                       filterable multiple style="width:100%;"
                                                       :multiple-limit="10">
                                                <el-option v-for="item in plantRetentionList" :key="item.id"
                                                           :value="item.name"
                                                           :label="item.name">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                        <el-table border :data="facCase.attachList">
                                            <el-table-column label="操作" width="80" class-name="compact-cell">
                                                <template #default="scope">
                                                    <el-button-group v-show="allowChange">
                                                        <el-button plain :icon="Plus" type="primary" size="small"
                                                                   title="添加附件"
                                                                   @click="newAttach"></el-button>
                                                        <el-popconfirm confirm-button-text="确认" cancel-button-text="取消"
                                                                       title="确认删除吗"
                                                                       @confirm="deleteAttach(scope.row,scope.$index)">
                                                            <template #reference>
                                                                <el-button plain :icon="Delete" type="danger"
                                                                           size="small"></el-button>
                                                            </template>
                                                        </el-popconfirm>
                                                    </el-button-group>
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="附件">
                                                <template #default="scope">
                                                    <el-select v-model="scope.row.name" filterable>
                                                        <el-option v-for="item in attach" :key="item.id"
                                                                   :value="item.name"></el-option>
                                                    </el-select>
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="数量" width="110">
                                                <template #default="scope">
                                                    <el-input v-only-int v-model.number="scope.row.num"></el-input>
                                                </template>
                                            </el-table-column>

                                        </el-table>
                                    </el-col>
                                    <el-col :span="16">
                                        <el-tabs type="border-card" v-model="caseTab" style="min-height: 180px;">
                                            <el-tab-pane name="file">
                                                <template #label>
                                                    <span>图片</span>
                                                    <span class="text-danger"
                                                          v-if="picList.length">
                                                        [{{ picList.length }}]
                                                    </span>
                                                </template>
                                                <el-upload
                                                    multiple accept="image/*"
                                                    action="#"
                                                    v-model:file-list="picList"
                                                    :limit="maxFileNum"
                                                    :before-upload="beforePicUpload"
                                                    :auto-upload="true"
                                                    :http-request="uploadPic"
                                                    :on-preview="previewPic"
                                                    :on-remove="removePic"
                                                    :on-exceed="caseFileExceed">
                                                    <el-button type="primary" plain v-show="allowChange">选择图片</el-button>
                                                </el-upload>
                                            </el-tab-pane>
                                            <el-tab-pane name="plant">
                                                <template #label>
                                                    <span>种植参数</span>
                                                    <span class="text-danger"
                                                          v-if="hasPlant">
                                                        [{{ plantNum }}]
                                                    </span>
                                                </template>
                                                <el-table :data="facCase.plantList" border max-height="400"
                                                          size="small">
                                                    <el-table-column label="操作" width="90">
                                                        <template #default="scope">
                                                            <el-button-group>
                                                                <el-button-group v-show="allowChange">
                                                                    <el-button plain :icon="Plus" type="primary"
                                                                               size="small"
                                                                               title="添加种植参数"
                                                                               @click="addPlant(scope.$index)"></el-button>
                                                                    <el-popconfirm confirm-button-text="确认"
                                                                                   cancel-button-text="取消"
                                                                                   title="确认删除吗"
                                                                                   @confirm="deletePlant(scope.$index)">
                                                                        <template #reference>
                                                                            <el-button plain :icon="Delete"
                                                                                       type="danger"
                                                                                       size="small"></el-button>
                                                                        </template>
                                                                    </el-popconfirm>
                                                                </el-button-group>
                                                            </el-button-group>
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column label="牙位" width="385" class-name="compact-cell"
                                                                     label-class-name="text-danger">
                                                        <template #default="scope">
                                                            <table-tooth-mini
                                                                :key="scope.$index"
                                                                v-model:pos1="scope.row.pos1"
                                                                v-model:pos2="scope.row.pos2"
                                                                v-model:pos3="scope.row.pos3"
                                                                v-model:pos4="scope.row.pos4"
                                                                v-model:upper-jaw="scope.row.upperJaw"
                                                                v-model:lower-jaw="scope.row.lowerJaw"
                                                                @change-tooth="val=>setPlantNum(val,scope.row)"
                                                            />
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column label="种植系统" width="120" class-name="compact-cell">
                                                        <template #default="scope">
                                                            <el-select v-model="scope.row.plantSystem"
                                                                       placeholder="种植系统" filterable>
                                                                <el-option v-for="item in plantSystemList"
                                                                           :key="item.id" :value="item.name"
                                                                           :label="item.name"></el-option>
                                                            </el-select>
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column label="种植固位" width="120" class-name="compact-cell">
                                                        <template #default="scope">
                                                            <el-select v-model="scope.row.plantRetention"
                                                                       placeholder="种植固位" filterable>
                                                                <el-option v-for="item in plantRetentionList"
                                                                           :key="item.id" :value="item.name"
                                                                           :label="item.name"></el-option>
                                                            </el-select>
                                                        </template>
                                                    </el-table-column>

                                                </el-table>
                                            </el-tab-pane>
                                            <el-tab-pane name="bigFile">
                                                <template #label>
                                                    <span>大文件</span>
                                                    <span class="text-danger"
                                                          v-if="showBigFileList.length">
                                                        [{{ showBigFileList.length }}]
                                                    </span>
                                                </template>
                                                <el-space style="width: 100%;">
                                                    <el-upload
                                                        style="min-width: 350px;"
                                                        action="#"
                                                        v-model:file-list="showBigFileList"
                                                        :before-upload="beforeBigFileUpload"
                                                        :limit="maxFileNum"
                                                        :auto-upload="true"
                                                        :http-request="uploadBigFile"
                                                        :on-remove="removeBigFile"
                                                        :on-preview="previewBigFile"
                                                        :on-exceed="caseFileExceed">
                                                        <template #tip>
                                                            <span class="text-danger">大文件上传7天后会自动删除</span>
                                                        </template>
                                                        <template #trigger>
                                                            <el-button type="primary" plain v-show="allowChange"
                                                                       :loading="uploadBigFileLoading">
                                                                选择文件
                                                            </el-button>
                                                        </template>
                                                        <el-progress v-show="!!progress" :percentage="progress"
                                                                     style="width:200px;"></el-progress>
                                                    </el-upload>
                                                </el-space>
                                            </el-tab-pane>
                                        </el-tabs>
                                    </el-col>
                                </el-row>
                            </el-form>
                            <el-row :gutter="5">
                                <el-col :span="24" style="padding-left: 100px;padding-top:10px;">
                                    <el-button type="primary" :icon="Edit" @click="handleSaveCase"
                                               v-show="allowChange"
                                               :loading="saveCaseLoading">保存信息
                                    </el-button>
                                    <el-button type="danger" @click="showValidCase"
                                               v-show="!!facCase.id && !!facCase.isValid && facCase.caseState<caseStateEnum.入件"
                                               :icon="Delete">
                                        作废
                                    </el-button>
                                </el-col>
                            </el-row>
                        </el-scrollbar>
                    </el-tab-pane>
                    <el-tab-pane label="在线沟通" name="comment" :disabled="!facCase.id">
                        <template #label>
                            <el-tooltip effect='light' placement='top' content="请先在左侧列表选择订单">
                                在线沟通
                            </el-tooltip>
                        </template>
                        <el-descriptions :column="4" border>
                            <el-descriptions-item width="12.5%" label="订单号">
                                {{ facCase.caseNo }}
                            </el-descriptions-item>
                            <el-descriptions-item width="12.5%" label="医院">
                                {{ facCase.facHospitalName }}
                            </el-descriptions-item>
                            <el-descriptions-item width="12.5%" label="医生">
                                {{ facCase.facDoctorName }}
                            </el-descriptions-item>
                            <el-descriptions-item label="患者">
                                {{ facCase.patientName }}
                            </el-descriptions-item>
                            <el-descriptions-item label="类型">
                                {{ getCaseTypeName(facCase.caseType) }}
                            </el-descriptions-item>
                            <el-descriptions-item label="入件日期">
                                {{ moment(facCase.inDate).format('yyyy-MM-DD') }}
                            </el-descriptions-item>
                            <el-descriptions-item label="医生备注" :span="2">
                                {{ facCase.doctorRemark }}
                            </el-descriptions-item>
                        </el-descriptions>
                        <el-table :data="facCase.productList" border style="width: 100%">
                            <el-table-column label="产品" prop="productName"></el-table-column>
                            <el-table-column label="种类">
                                <template #default="scope">
                                    {{
                                        getProductFullType(scope.row.productId)
                                    }}
                                </template>
                            </el-table-column>
                            <el-table-column label="牙色" prop="color"></el-table-column>
                            <el-table-column min-width="170" label="牙位">
                                <template #default="scope">
                                    <table-tooth
                                        :key="scope.row.id"
                                        :pos1="scope.row.pos1"
                                        :pos2="scope.row.pos2"
                                        :pos3="scope.row.pos3"
                                        :pos4="scope.row.pos4"
                                        :upper-jaw="scope.row.upperJaw"
                                        :lower-jaw="scope.row.lowerJaw"
                                    />
                                </template>
                            </el-table-column>
                            <el-table-column label="数量" prop="num"></el-table-column>
                            <el-table-column label="小计" prop="sum"></el-table-column>
                        </el-table>
                        <el-scrollbar style="width: 100%;margin-top: 20px;height:1000px;    ">
                            <el-input placeholder="输入内容后回车即可发送信息，最多200字" ref="commentRef" v-model.trim="commentContent"
                                      maxlength="200" type="textarea"
                                      :readonly="sendCaseCommentLoading" @keydown.enter="handleSendComment"></el-input>
                            <el-space :size="20">
                                <el-upload action="#"
                                           class="avatar-uploader" accept="image/*"
                                           :before-upload="beforePicUpload"
                                           :auto-upload="true"
                                           :on-success="successPicUpload"
                                           :http-request="uploadPic"
                                           :show-file-list="false">
                                    <template #trigger>
                                        <el-button type="primary" :icon="Files" plain :loading="sendCaseCommentLoading">
                                            选择文件
                                        </el-button>
                                    </template>
                                </el-upload>
                                <el-button type="warning" plain :loading="readAllCaseCommentLoading"
                                           v-show="hasUnreadComments"
                                           @click="handleReadAll">
                                    消息已处理
                                </el-button>
                            </el-space>
                            <el-timeline style="margin-top: 20px;">
                                <el-timeline-item placement="top" v-for="item in caseCommentList"
                                                  :type="item.sendSource===userTypeEnum.Factory?(item.isRead===0?'danger':'primary'):''"
                                                  :timestamp="moment(item.addTime).format('yyyy-MM-DD HH:mm')+'  '+(item.sendSource===userTypeEnum.Hospital?item.facDoctorName:'')">
                                    <p class="text-primary" v-if="item.sendSource===userTypeEnum.Factory">
                                        {{ item.facWorkerName }} --
                                        {{ item.factoryName }}</p>
                                    <el-card style="max-width: 500px;" :shadow="item.sendSource===userTypeEnum.Factory?'always':'never'" :body-style="{padding:'0px 10px'}">
                                        <h3 v-if="!item.fileId">{{ item.commentContent }}</h3>
                                        <el-link type="primary" v-else :href="picPathPrefix+item.fileToken"
                                                 target="_blank">{{ item.commentContent }}
                                        </el-link>
                                    </el-card>
                                </el-timeline-item>
                            </el-timeline>
                        </el-scrollbar>
                    </el-tab-pane>
                    <el-tab-pane label="操作历史" name="history" :disabled="!facCase.id">
                        <template #label>
                            <el-tooltip effect='light' placement='top' content="请先在左侧列表选择订单">
                                操作历史
                            </el-tooltip>
                        </template>
                        <el-table :data="caseHistoryList" v-loading="getCaseHistoryListLoading" border
                                  style="width: 800px;">
                            <el-table-column label="操作员工" prop="workerName" width="100"></el-table-column>
                            <el-table-column label="操作时间" prop="operTime" width="200"
                                             :formatter="datetimeFormat"></el-table-column>
                            <el-table-column label="操作类型" prop="operTypeName" width="100"></el-table-column>
                            <el-table-column label="操作内容" prop="operContent" width="400">
                                <template #default="scope">
                                    <p v-html="scope.row.operContent"></p>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="生产流程" name="step" :disabled="!facCase.id">
                        <template #label>
                            <el-tooltip effect='light' placement='top' content="请先在左侧列表选择订单">
                                生产流程
                            </el-tooltip>
                        </template>
                        <el-radio-group v-model="currentStepProduct">
                            <el-radio-button v-for="item in productStep" :label="item.id" :key="item.id">
                                {{ item.productName }}
                            </el-radio-button>
                        </el-radio-group>
                        <el-timeline style="margin-top: 20px;">
                            <el-timeline-item v-for="item in showStepSimpleList" :timestamp="item.operTime"
                                              placement="top">
                                <h4>{{ item.workerName }} -- {{ item.stepName }}</h4>
                            </el-timeline-item>
                        </el-timeline>
                    </el-tab-pane>
                    <el-tab-pane label="快递信息" name="express" :disabled="!facCase.id">
                        <template #label>
                            <el-tooltip effect='light' placement='top' content="请先在左侧列表选择订单">
                                快递信息
                            </el-tooltip>
                        </template>
                        <el-space style="margin-bottom:10px;padding-left:10px; ">
                            <el-tag size="large">{{ facCase.caseNo }}</el-tag>
                        </el-space>
                        <el-form :model="caseExpress" label-width="100px">
                            <el-form-item label="快递公司">
                                <el-select size="default" v-model="caseExpress.expressCompany" filterable
                                           class="input-medium">
                                    <el-option v-for="item in expressCompany" :key="item.id"
                                               :value="item.id" :label="item.name">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="快递单号">
                                <el-input maxlength="100" v-model.trim="caseExpress.expressNo"
                                          class="input-large"></el-input>
                            </el-form-item>
                            <el-form-item label="产品数量">
                                <el-input-number maxlength="3" v-model.trim="caseExpress.num"
                                                 class="input-medium"></el-input-number>
                            </el-form-item>
                            <el-form-item label="发件日期">
                                <el-date-picker type="date" v-model="caseExpress.pickUpDate" class="input-medium"
                                                size="default"></el-date-picker>
                            </el-form-item>
                        </el-form>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
        <el-dialog v-model="validCaseVisible" title="作废订单" width="500px">
            <el-alert type="error" :closable="false">订单一单作废，无法恢复，请慎重操作</el-alert>
            <el-form style="margin-top:10px;">
                <el-form-item label="作废原因" required>
                    <el-input v-model.trim="validCaseParam.validReason" maxlength="200" clearable></el-input>
                </el-form-item>
            </el-form>
            <footer>
                <div style="width: 100%;display: flex;padding-top:10px;">
                    <div class="flex-grow"></div>
                    <el-button type="primary" :icon="Delete" @click="handleValidCase" :loading="validCaseLoading">
                        确认作废
                    </el-button>
                </div>
            </footer>
        </el-dialog>
        <el-dialog v-model="redoReasonVisible">
            <el-checkbox-group v-model="redoReasons">
                <el-checkbox border v-for="item in redoReason" :key="item.id" :label="item.name">{{ item.name }}
                </el-checkbox>
            </el-checkbox-group>
            <footer>
                <div style="width: 100%;display: flex;">
                    <div class="flex-grow"></div>
                    <el-button type="primary" :icon="Check" @click='saveRedoReason'>
                        确认
                    </el-button>
                    <el-button @click="redoReasonVisible=false">取消</el-button>
                </div>
            </footer>
        </el-dialog>

        <el-dialog v-model="casePicVisible">
            <el-image :src="picPathPrefix+casePicToken">
            </el-image>
        </el-dialog>
    </div>
</template>
<script setup>
import {ref, reactive, onMounted, watch, inject, computed, toRaw} from 'vue';
import {
    caseStateEnum,
    caseState,
    caseTypeEnum,
    auditStateEnum,
    caseType,
    tryState,
    genderList, fileUseEnum,
    orderSourceEnum, userTypeEnum
} from "@/util/enum";
import {
    Plus,
    Search,
    Refresh,
    Edit,
    Delete,
    Check,
    Hide,
    View,
    Setting,
    Files,
    Printer,
    Van,
    Close
} from '@element-plus/icons-vue';
import {useStore} from 'vuex'
import {
    commonListDefaultFilter,
    commonListFilter,
    dateRangeDefaultTime,
    enumTypeFormatter,
    dateFormat,
    datetimeFormat
} from "@/util/formatter";
import {
    useValidCase,
    useGetSimplePageCase,
    useGetCase,
    useSaveCase,
    useGetHistoryListCase,
    useGetSimpleListCaseProductStep
} from "@/use/case/useCase";
import {ElMessage} from "element-plus";
import {useRoute} from "vue-router";
import moment from "moment";

const store = useStore()
const route = useRoute()
const casePageRef = ref(null)

const currentDoctor = computed(() => store.state.currentDoctor)
const storeHospitalDetail = store.state.hospital
const storeDoctorList = computed(() => store.state.doctorList)
const productDetails = computed(() => store.state.productDetails)
const storeProductList = computed(() => store.state.productList)
const toothColorList = computed(() => store.state.toothColorList)

const onlyAudit = ref(false)
onMounted(async () => {
    setData()
    initGetSimplePageCase()
    await searchPage()
    if (route.query && route.query.id) {
        await getFacCase(route.query.id)
        if (route.query.type === 'comment') {
            curTab.value = 'comment'
            await changeTab()
        }
    } else {
        newCase()
    }
})
const searchPage = async () => {
    getSimplePageCaseParam.auditState = onlyAudit.value ? auditStateEnum.待审核 : null
    await getSimplePageCase()
}
// 文件
const picList = ref([])//页面显示的文件
const {
    uploadResult,
    uploadFileList,//实际文件信息
    uploadPic,
    picId,
    picToken
} = useUploadPic('casePic')
const maxFileNum = 5//最多文件上传数量
const caseFileExceed = () => {
    ElMessage.error(`最多上传${maxFileNum}个文件`)
}
const removePic = file => {
    let fileIndex = uploadFileList.value.findIndex(x => x.fileName === file.name)
    try {
        uploadFileList.value.splice(fileIndex, 1)
    } catch (e) {
        ElMessage.warning('没找到要删除的文件')
    }
}
const casePicVisible = ref(false)
const casePicToken = ref('')
const previewPic = file => {
    let caseFile = uploadFileList.value.find(x => x.fileName === file.name)
    if (file.name.indexOf('jpg') >= 0 || file.name.indexOf('jpeg') >= 0 || file.name.indexOf('png') >= 0) {
        if (caseFile) {
            casePicToken.value = caseFile.fileToken
            casePicVisible.value = true
        } else {
            casePicToken.value = ''
        }
    } else {
        downFile(picPathPrefix + caseFile.fileToken, file.name)
    }
}
// 产品
const setProductNum = (val, row) => {
    row.num = val
    getTotalPrice()
}

const newProduct = () => {
    if (facCase.productList.length && facCase.productList.length >= 10) {
        ElMessage.error('不能超过10个产品，请检查')
        return
    }
    let discount = 100
    if (storeDoctor && facCase.isPrivate === 1) {
        //如果是私人单位 需要获取医生私人折扣打折
        discount = storeDoctor.discountPercent ? parseInt(storeDoctor.discountPercent) : 100
    }
    let product = newCaseProduct()
    product.discount = discount
    facCase.productList.push(product)
}
const deleteProduct = (row, index) => {
    if (facCase.productList.length && facCase.productList.length <= 1) {
        ElMessage.error('至少要有1个产品，请检查')
        return
    }
    facCase.productList.splice(index, 1)
    getTotalPrice()
}
const changeDiscountType = (row, discountType) => {
    row.discountType = discountType
    getTotalPrice()
}
// 附件
const newAttach = () => {
    if (facCase.attachList.length && facCase.attachList.length >= 10) {
        ElMessage.error('不能超过10个附件，请检查')
        return
    }
    facCase.attachList.push({
        id: 0,
        name: '',
        num: 1
    })
}
const deleteAttach = (row, index) => {
    if (facCase.attachList.length && facCase.attachList.length <= 1) {
        ElMessage.error('不能全部删除，请检查')
        return
    }
    facCase.attachList.splice(index, 1)
}
// 牙色
const searchColor = (val, cb) => {
    let res = []
    if (!val) {
        cb(toothColorList.value)
        return
    }
    if (toothColorList.value && toothColorList.value.length) {
        res = toothColorList.value.filter(x => x.color.indexOf(val) >= 0)
    }
    cb(res)
}
//缓存数据字典
import {useGetDataValueListFromStore} from "@/use/system/useData";

const {
    getDataValueListFromStore,
    getDataIdFromStore
} = useGetDataValueListFromStore()
const district = ref([])
const redoReason = ref([])
const redoReasonId = getDataIdFromStore('redoReason')
const produceTimespan = ref([])
const produceTimespanId = getDataIdFromStore('produceTimespan')
const attach = ref([])
const attachId = getDataIdFromStore('attach')
const expressCompany = ref([])
const setData = () => {
    district.value = getDataValueListFromStore('district')
    redoReason.value = getDataValueListFromStore('redoReason')
    produceTimespan.value = getDataValueListFromStore('produceTimespan')
    attach.value = getDataValueListFromStore('attach')
    expressCompany.value = getDataValueListFromStore('expressCompany')
}

//返工原因
const redoReasonVisible = ref(false)
const redoReasons = ref([])
const saveRedoReason = () => {
    let redoReasonStr = redoReasons.value.join(',')
    facCase.redoReason = facCase.redoReason + (facCase.redoReason ? ',' : '') + redoReasonStr
    redoReasonVisible.value = false
}
const showRedoReason = () => {
    redoReasonVisible.value = true
    redoReasons.value = []
}

const handleSaveCase = async () => {
    facCase.saveOrderSource = orderSourceEnum.医生下单平台
    let casePicList = []
    !!uploadFileList.value.length && (casePicList = uploadFileList.value.map(x => {
        return {
            id: x.id,
            fileId: x.fileId,
            fileUse: fileUseEnum.订单图片,
            casePicType: '订单图片'
        }
    }))
    let caseBigFileList = []
    !!bigFileList.value.length && (caseBigFileList = bigFileList.value.map(x => {
        return {
            id: x.id,
            fileId: x.fileId,
            fileUse: fileUseEnum.订单口扫文件,
            casePicType: ''
        }
    }))
    facCase.fileList = casePicList.concat(caseBigFileList)

    setCasePlant()
    facCase.materialList = facCase.materialList.filter(x => !!x.materialId)
    facCase.plantList = facCase.plantList.filter(x => !!x.num)
    await saveCase()
}

const tableRowClass = ({row, index}) => {
    if (!row.isValid) {
        return 'danger-row'
    } else {
        return ''
    }
}
// 选择一行
const chooseRow = async row => {
    initBigFile()
    await getFacCase(row.id)
    caseNoRef.value && caseNoRef.value.select()
}
// 获取订单信息 获取缓存的订单医院和医生信息
const getFacCase = async id => {
    await getCase(id)
    Object.assign(facCase, caseInfo)
    setCaseStoreHospitalAndDoctor()
    if (facCase.isValid === 0) {
        curTab.value = 'add'
    }
    if (!facCase.attachList || !facCase.attachList.length) newAttach()
    if (!facCase.plantList || !facCase.plantList.length) addPlant(0)
    if (facCase.fileList && facCase.fileList.length) {
        uploadFileList.value = facCase.fileList.filter(x => x.fileUse === fileUseEnum.订单图片)
        let bigArr = facCase.fileList.filter(x => x.fileUse === fileUseEnum.订单口扫文件)
        if (bigArr.length) {
            bigFileList.value = bigArr.map(x => {
                return {
                    id: x.id,
                    fileId: x.fileId,
                    fileToken: x.fileToken,
                    orgFileName: x.fileName,
                }
            })
        }

    } else {
        uploadFileList.value = []
    }
    picList.value = []
    showBigFileList.value = []
    if (!!uploadFileList.value.length) {
        for (let file of uploadFileList.value) {
            picList.value.push({
                name: file.fileName
            })
        }
    }
    if (bigFileList.value.length) {
        showBigFileList.value = bigFileList.value.map(x => {
            return {
                name: x.orgFileName
            }
        })
    }

    caseFormRef.value && caseFormRef.value.clearValidate()
    changeTab()
}
// 根据关联订单号填充订单信息
const getCaseInfoBySourceCaseNo = async () => {
    if (!facCase.sourceCaseNo) return
    if (!!facCase.id) return
    await getCaseByNo(facCase.sourceCaseNo)
    if (!caseInfo.id) return
    facCase.isTry = caseInfo.isTry
    facCase.tryState = caseInfo.tryState
    facCase.facDoctorId = caseInfo.facDoctorId
    facCase.facDoctorName = caseInfo.facDoctorName
    facCase.facHospitalId = caseInfo.facHospitalId
    facCase.facHospitalName = caseInfo.facHospitalName
    facCase.districtId = caseInfo.districtId
    facCase.agentId = caseInfo.agentId
    facCase.patientName = caseInfo.patientName
    facCase.patientAge = caseInfo.patientAge
    facCase.patientPhoneNo = caseInfo.patientPhoneNo
    facCase.gender = caseInfo.gender
    facCase.isPrivate = caseInfo.isPrivate
    facCase.isInvoice = caseInfo.isInvoice
    facCase.productList = caseInfo.productList
    getTotalPrice()
    setCaseStoreHospitalAndDoctor()
    caseFormRef.value && caseFormRef.value.clearValidate()
}
// 切换tab
const curTab = ref('add')
const caseExpress = reactive({
    expressCompany: '',
    expressNo: '',
    num: 0,
    pickUpDate: '',
})
const changeTab = async () => {
    if (curTab.value === 'add') {

    } else if (curTab.value === 'history') {
        await getCaseHistoryList(facCase.id)
    } else if (curTab.value === 'step') {
        await chooseStep()
    } else if (curTab.value === 'comment') {
        commentRef.value && commentRef.value.focus()
        await getListCaseComment(facCase.id)
    }
}
//时间期限
const searchDateRange = ref([])
watch(searchDateRange, (newVal, oldVal) => {
    if (newVal && newVal.length) {
        getSimplePageCaseParam.inBeginDate = newVal[0]
        getSimplePageCaseParam.inEndDate = newVal[1]
    } else {
        getSimplePageCaseParam.inBeginDate = ''
        getSimplePageCaseParam.inEndDate = ''
    }
})
const searchOutDateRange = ref([])
watch(searchOutDateRange, (newVal, oldVal) => {
    if (newVal && newVal.length) {
        getSimplePageCaseParam.outBeginDate = newVal[0]
        getSimplePageCaseParam.outEndDate = newVal[1]
    } else {
        getSimplePageCaseParam.outBeginDate = ''
        getSimplePageCaseParam.outEndDate = ''
    }
})

const initGetSimplePageCase = () => {
    initGetSimplePageCaseParam()
    getSimplePageCaseParam.inEndDate = moment().format('yyyy-MM-DD 23:59:59')
    getSimplePageCaseParam.inBeginDate = moment().subtract(30, 'days').format('yyyy-MM-DD 00:00:00')
    searchDateRange.value = [getSimplePageCaseParam.inBeginDate, getSimplePageCaseParam.inEndDate]
}

// 订单
const {
    addPlant,
    deletePlant,
    newCaseProduct,
    facCase,
    initCase,
    caseFormRef,
    caseSaveRules,
    saveCaseLoading,
    saveCaseResult,
    saveCaseInfo,
    saveCase,
    getCaseTotalPrice,
    getTotalPrice,
    getTotalDiscount,
    changeCaseProductPrice,
    totalDiscount,
    totalNum,
} = useSaveCase()
const {
    caseGetLoading,
    caseInfo,
    getCase,
    getCaseByNo
} = useGetCase()
const {
    getSimplePageCaseParam,
    initGetSimplePageCaseParam,
    getSimplePageCaseLoading,
    casePage,
    getSimplePageCaseResult,
    getSimplePageCase,
} = useGetSimplePageCase()
const {
    validCase,
    validCaseLoading,
    validCaseParam,
    validCaseResult
} = useValidCase()

// 作废订单
const validCaseVisible = ref(false)
const showValidCase = () => {
    validCaseVisible.value = true
    validCaseParam.id = facCase.id
    validCaseParam.validReason = '录入信息错误'
}
const handleValidCase = async () => {
    await validCase()
}
watch(validCaseResult, (newVal, oldVal) => {
    if (!!newVal) {
        validCaseVisible.value = false
        facCase.isValid = 0
        let pageItem = casePage.value.find(x => x.id === facCase.id)
        pageItem.isValid = 0
    }
})

// 新建订单
watch(saveCaseResult, async (newVal, oldVal) => {
    if (!!newVal) {
        if (!facCase.id) {
            if (facCase.productList && facCase.productList.length) {
                //更新缓存：上次产品
                let caseProductIds = facCase.productList.map(x => x.productId)
                store.commit('setLastProductIds', caseProductIds)
                for (let productId of caseProductIds) {
                    store.commit('addProductCount', productId)
                }
            }
            Object.assign(caseInfo, facCase)
            caseInfo.id = saveCaseInfo.id
            caseInfo.caseNo = saveCaseInfo.caseNo
            //清空
            newCase()
            await getSimplePageCase()
        } else {
            //修改
            await getSimplePageCase()
            Object.assign(caseInfo, facCase)
            if (!facCase.plantList || !facCase.plantList.length) addPlant(0)
            casePageRef.value.setCurrentRow(casePage.value.find(x => x.id === facCase.id))
        }
    }
})
//从caseInfo中复制医院和医生信息
const getHospitalDoctorFromCaseInfo = () => {
    facCase.facHospitalId = caseInfo.facHospitalId
    facCase.facDoctorId = caseInfo.facDoctorId
    facCase.districtId = caseInfo.districtId
    facCase.facWorkerId = caseInfo.facWorkerId
    facCase.agentId = caseInfo.agentId
    facCase.isInvoice = caseInfo.isInvoice
    facCase.isPrivate = caseInfo.isPrivate
    setCaseStoreHospitalAndDoctor()
}

const caseNoRef = ref(null)
const patientNameRef = ref()
const newCase = () => {
    initCase()
    // 根据设置不清空医院和医生信息
    if (!!caseInfo.id) {
        getHospitalDoctorFromCaseInfo()
    } else {
        facCase.facHospitalId = currentDoctor.value.facHospitalId
        changeCaseFacHospital()
        facCase.facDoctorId = currentDoctor.value.facDoctorId
        setCaseStoreHospitalAndDoctor()
    }
    curTab.value = 'add'
    if (produceTimespan.value && produceTimespan.value.length) facCase.makeDays = produceTimespan.value[0].name
    calScheduleDeliveryDate()
    setTimeout(_ => {
        casePageRef.value && casePageRef.value.setCurrentRow()
        patientNameRef.value && patientNameRef.value.focus()
        caseFormRef.value && caseFormRef.value.clearValidate()
    }, 0)
    uploadFileList.value = []
    picList.value = []
    showBigFileList.value = []
    bigFileList.value = []
    plantSystems.value = []
    plantRetentions.value = []
}
const calScheduleDeliveryDate = () => {
    if (facCase.inDate) {
        let makeDays = facCase.makeDays ? parseInt(facCase.makeDays) : 0
        let extraDays = facCase.extraDays ? parseInt(facCase.extraDays) : 0
        facCase.scheduleDeliveryDate = moment(facCase.inDate).add(makeDays + extraDays, 'days').format('yyyy-MM-DD 23:59:59')
    }
}
import TableToothInput from "@/components/tableToothInput";
import {useUploadPic, picPathPrefix, useDownload, beforePicUpload, useUploadBigFile} from "@/use/system/useFile";

const {
    downFile,
    downFileLoading
} = useDownload()
// 搜索

const searchCase = async () => {
    await searchPage()
    if (casePage.value.length === 1) {
        casePageRef.value.setCurrentRow(casePage.value[0])
        chooseRow(casePage.value[0])
    }
}

// 获取产品价格
const setCaseStoreHospitalAndDoctor = () => {
    // 获取缓存中的医院和医生
    storeDoctor = storeDoctorList.value.find(x => x.id === facCase.facDoctorId)
    storeDoctor && (storeDoctor.priceList = store.state.privatePriceList.filter(x => x.priceId === storeDoctor.discountId))
}
const changeCaseFacHospital = () => {
    // 获取医院的销售员、片区、代理人、是否开票
    if (storeHospitalDetail) {
        facCase.districtId = storeHospitalDetail.districtId
        facCase.facWorkerId = storeHospitalDetail.salesman
        facCase.agentId = storeHospitalDetail.agentId
        facCase.isInvoice = storeHospitalDetail.isInvoice
    }
}
let storeDoctor = null
const changeCaseFacDoctor = (changePrivate = false) => {
    if (!facCase.facDoctorId) {
        storeDoctor = null
        return
    }
    storeDoctor = storeDoctorList.value.find(x => x.id === facCase.facDoctorId)
    storeDoctor && (storeDoctor.priceList = store.state.privatePriceList.filter(x => x.priceId === storeDoctor.discountId))
    if (storeDoctor && facCase.isPrivate === 1) {
        //如果订单是私人单位 需要修改所有产品的折扣默认为医生设置的私人折扣
        if (facCase.productList && facCase.productList.length) {
            facCase.productList.forEach(x => {
                x.discount = parseInt(storeDoctor.discountPercent)
                x.discountType = 0
                changeCaseProduct(x)
            })
        }
    } else if (storeDoctor && facCase.isPrivate === 0 && changePrivate) {
        //不是私人单位 重置折扣、单价、小计、总计等金额先关数据
        if (facCase.productList && facCase.productList.length) {
            facCase.productList.forEach(x => {
                x.discount = 100
                x.discountType = 0
                changeCaseProduct(x)
            })
        }
    }
}
const changeCaseProduct = row => {
    changeMakeDays()
    changeCaseProductPrice(row, productDetails.value, storeHospitalDetail, storeDoctor)
}
// 根据产品生产天数 修改订单生产天数
const changeMakeDays = () => {
    if (!facCase.productList.length) return
    let maxMakeDays = 0
    for (let product of facCase.productList) {
        let productDetail = productDetails.value.find(x => x.id === product.productId)
        if (!productDetail) continue
        if (maxMakeDays < productDetail.makeDays) {
            maxMakeDays = productDetail.makeDays
        }
    }
    facCase.makeDays = maxMakeDays
    calScheduleDeliveryDate()
}
// 获取枚举名称
const getCaseTypeName = val => {
    let type = caseType.find(x => x.id === val)
    return type.name
}
const getCaseStateName = val => {
    let state = caseState.find(x => x.id === val)
    return state ? state.name : ''
}
const getProductFullType = val => {
    if (!store.state.productDetails) return ''
    let product = store.state.productDetails.find(x => x.id === val)
    if (product) {
        return product.productTypeName + ' - ' + product.productBelongName
    }
}
// 订单补充信息
const caseTab = ref('file')
// 订单操作日志
const {
    caseHistoryList, getCaseHistoryList, getCaseHistoryListLoading
} = useGetHistoryListCase()


// 生产流程
const {
    caseProductStepSimpleList,
    getSimpleListCaseProductStepLoading,
    getSimpleListCaseProductStep
} = useGetSimpleListCaseProductStep()
const productStep = ref([])//订单产品的生产流程
const currentStepProduct = ref(0)//当前显示生产流程的产品
const showStepSimpleList = computed(() => {
    let item = productStep.value.find(x => x.id === currentStepProduct.value)
    if (item) {
        return item.stepList
    } else {
        return []
    }
})
//当前显示的生产流程
const chooseStep = async () => {
    if (!facCase.id) return
    productStep.value = []
    for (let caseProduct of facCase.productList) {
        await getSimpleListCaseProductStep(caseProduct.id)
        let tmpStepList = JSON.parse(JSON.stringify(caseProductStepSimpleList.value))
        productStep.value.push({
            id: caseProduct.id,
            productName: caseProduct.productName,
            stepList: tmpStepList
        })
    }
    currentStepProduct.value = facCase.productList[0].id
}
//在线沟通
const commentRef = ref()
const commentContent = ref('')
import {useGetListCaseComment, useSendCaseComment, useReadAllCaseComment} from "@/use/case/useCaseComment";

const {
    caseComment,
    initCaseComment,
    sendCaseComment,
    sendCaseCommentLoading
} = useSendCaseComment()
const {
    caseCommentList,
    getListCaseCommentLoading,
    getListCaseComment
} = useGetListCaseComment()
const {
    readAllCaseCommentResult,
    readAllCaseComment,
    readAllCaseCommentLoading
} = useReadAllCaseComment()
const hasUnreadComments = computed(() => {
    return caseCommentList.value.some(x => x.isRead === 0)
})
const handleReadAll = async () => {
    await readAllCaseComment(facCase.id)
}
watch(readAllCaseCommentResult, (n, o) => {
    if (!!n) getListCaseComment(facCase.id)
})

const finishSendCaseComment = async () => {
    let res = await sendCaseComment()
    if (!!res) {
        caseCommentList.value.splice(0, 0, res)
        commentContent.value = ''
        commentRef.value && commentRef.value.focus()
        let unreadItems = caseCommentList.value.filter(x => x.isRead === 0)
        if (unreadItems && unreadItems.length) {
            for (let item of unreadItems) {
                item.isRead = 1
            }
        }
    }
}
const handleSendComment = async () => {
    initCaseComment()
    caseComment.facDoctorName = currentDoctor.value.facDoctorName
    caseComment.facHospitalName = currentDoctor.value.facHospitalName
    caseComment.addTime = new Date()
    caseComment.caseId = facCase.id
    caseComment.commentContent = commentContent.value
    await finishSendCaseComment()
}
const newCaseCommentNum = inject('newCaseCommentNum')
const showCaseCommentNum = inject('showCaseCommentNum')
const newCaseComment = inject('newCaseComment')
watch(newCaseCommentNum, (n, o) => {
    if (curTab.value === 'comment' && facCase.id === newCaseComment.caseId) {
        caseCommentList.value.splice(0, 0, JSON.parse(JSON.stringify(toRaw(newCaseComment))))
    }
})
watch(showCaseCommentNum, (n, o) => {
    if (curTab.value === 'comment' && facCase.id === newCaseComment.caseId) {
        if ((!!newCaseComment.commentContent || !!newCaseComment.fileId) && !caseCommentList.value.some(x => x.id === newCaseComment.id)) {
            caseCommentList.value.splice(0, 0, JSON.parse(JSON.stringify(toRaw(newCaseComment))))
        }
    } else {
        facCase.id = newCaseComment.caseId
        getFacCase(newCaseComment.caseId)
        curTab.value = 'comment'
        changeTab()
        if (!!casePage.value.some(x => x.id === facCase.id)) {
            casePageRef.value.setCurrentRow(casePage.value.find(x => x.id === facCase.id))
        }
    }
})
const successPicUpload = async (param, file) => {
    initCaseComment()
    caseComment.facDoctorName = currentDoctor.value.facDoctorName
    caseComment.facHospitalName = currentDoctor.value.facHospitalName
    caseComment.addTime = new Date()
    caseComment.caseId = facCase.id
    caseComment.fileId = picId.value
    caseComment.fileToken = picToken.value
    caseComment.commentContent = file.name
    await finishSendCaseComment()
}

//种植
const plantSystemList = computed(() => store.state.plantSystemList)
const plantSystems = ref([])
const plantRetentionList = computed(() => store.state.plantRetentionList)
const plantRetentions = ref([])
// 获取订单种植系统和固位
const getCasePlant = () => {
    plantSystems.value = facCase.plantSystems ? facCase.plantSystems.split(',') : []
    plantRetentions.value = facCase.plantRetentions ? facCase.plantRetentions.split(',') : []
}
// 设置订单种植系统和固位
const setCasePlant = () => {
    facCase.plantSystems = plantSystems.value.join(',')
    facCase.plantRetentions = plantRetentions.value.join(',')
}
const setPlantNum = (val, row) => {
    row.num = val
}
const hasPlant = computed(() => facCase.plantList && facCase.plantList.length && facCase.plantList.filter(x => !!x.num).length)
const plantNum = computed(() => facCase.plantList.filter(x => !!x.num).length)

const allowChange = computed(() => !!facCase.isValid && facCase.caseState < caseStateEnum.入件)
// 大文件
const {
    uploadBigFileLoading,
    uploadBigFile,
    initBigFile,
    resultBigFile,
    progress,
    bigFileList,
    beforeBigFileUpload,
} = useUploadBigFile()
const showBigFileList = ref([])
const removeBigFile = file => {
    let fileIndex = bigFileList.value.findIndex(x => x.fileName === file.name)
    try {
        bigFileList.value.splice(fileIndex, 1)
    } catch (e) {
        ElMessage.warning('没找到要删除的文件')
    }
}
const previewBigFile = () => {
    // 大文件不提供预览
}
</script>
<style scoped>
.form-width {
    width: 1050px;
}
</style>