<template>
    <el-container class="login-container">
        <div class="bg"></div>
        <el-main class="login-main">
            <div class="login-box flex-row">
                <div class="left flex-row">
                    <div class="content flex-col">
                        <div class="welcome">欢迎登录</div>
                        <div class="website-name">存知科技医生录单系统</div>
                    </div>
                </div>
                <div class="right flex-row">
                    <div class="content flex-col">
                        <div class="input-div">
                            <input type="text" placeholder="账号" maxlength="20" v-model.trim="user.userName"
                                   ref="userNameRef"
                                   @keydown.enter="handleLogin"/>
                        </div>
                        <div class="input-div">
                            <input type="password" placeholder="密码" maxlength="50" v-model.trim="user.password"
                                   ref="passwordRef"
                                   @keydown.enter="handleLogin"/>
                        </div>
                        <div class="flex-row button-div">
                            <div class="flex-col">
                                <el-button class="login-btn" @click="handleLogin"
                                           :loading="loginLoading || refreshTokenLoading || loginHospitalLoading">登录
                                </el-button>
                                <div class="text-info" style="font-size: 13px;margin-top: 5px;">
                                    无法登陆？
                                    <span class="text-primary">请联系工厂开通服务</span>
                                </div>
                            </div>
                            <div class="forget flex-row">
                                <el-checkbox v-model="autoLogin" size="large" @change="changeAutoLogin"
                                             :true-label="1" :false-label="0">自动登录
                                </el-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </el-main>
        <el-footer class="login-footer">
            Copyright © 2023 All Rights Reserved. 杭州存知科技有限公司 版权所有
        </el-footer>

        <el-dialog v-model="doctorVisible" width="400px" :close-on-click-modal="false" :show-close="false"
                   title="选择工厂与医院">
            <el-radio-group v-model="doctorId">
                <el-radio v-for="item in doctorList" :key="item.facDoctorId" :label="item.facDoctorId" border
                          style="width: 320px;margin-bottom: 10px;">
                    {{ item.factoryName + '--' + item.facHospitalName + '--' + item.facDoctorName }}
                </el-radio>
            </el-radio-group>
            <footer>
                <div style="width: 100%;display: flex;">
                    <div class="flex-grow"></div>
                    <el-button type="primary" :loading="loginHospitalLoading" @click="loginHospital">
                        确认登录
                    </el-button>
                </div>
            </footer>
        </el-dialog>
    </el-container>
</template>

<script setup>
import {useLogin, useRefreshToken} from "@/use/system/useDoctorUser";
import {onMounted, ref} from "vue";
import {ElMessage} from "element-plus";

const {
    user,
    login,
    loginLoading,
    doctorList,
    doctorId,
    doctorVisible,
    loginHospitalLoading,
    loginHospital,
    loginHospitalByToken
} = useLogin()
const {
    refreshToken,
    refreshTokenLoading
} = useRefreshToken()
const userNameRef = ref()
const passwordRef = ref()
const autoLogin = ref(1)
onMounted(async () => {
    let storeAutoLogin = localStorage.getItem('autoLogin')
    autoLogin.value = storeAutoLogin === '1' ? 1 : 0
    if (autoLogin.value) {
        await refreshToken()
        await loginHospitalByToken()
    }
    let userName = localStorage.getItem('doctorUserName')
    if (userName) {
        user.userName = userName
        passwordRef.value.focus()
    } else {
        userNameRef.value.focus()
    }
})
const changeAutoLogin = () => {
    localStorage.setItem('autoLogin', autoLogin.value)
}
const handleLogin = async () => {
    if (!user.userName) {
        ElMessage.error('请输入用户名')
        userNameRef.value.focus()
        return
    } else if (!user.password) {
        ElMessage.error('请输入密码')
        passwordRef.value.focus()
        return
    }
    await login()
}


</script>

<style scoped lang="scss">
.login-container {
    width: 100%;
    height: 100%;
}

.bg {
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 100%;
    background: url("../assets/img/p_big3.jpg") no-repeat center center;
    background-size: 100% 100%;
    filter: blur(5px);
    -ms-filter: blur(5px);
    -webkit-filter: blur(5px);
}

.login-main {
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.login-box {
    width: 1000px;
    height: 850px;

    .left {
        width: 50%;
        color: white;
        align-items: center;
        justify-content: start;
        background-color: rgba(121, 106, 238, 0.9);

        .content {
            justify-content: start;
            font-family: "Microsoft YaHei";

            .welcome {
                font-size: 40px;
                font-weight: bold;
                padding-left: 35px;
            }

            .website-name {
                font-size: 16px;
                padding-left: 37px;
            }
        }
    }

    .right {
        width: 50%;
        align-items: center;
        justify-content: start;
        background-color: white;

        .content {
            padding-left: 35px;
            justify-content: start;
            font-family: "Microsoft YaHei";
            //border:1px solid red;
            width: 75%;

            .input-div {
                height: 60px;
                padding-bottom: 5px;
                border-bottom: 1px solid rgba(0, 0, 0, 0);

                input {
                    width: 100%;
                    height: 80%;
                    text-indent: 15px;
                    border: none;
                    background-color: rgba(0, 0, 0, 0);
                    outline: none;
                    color: black;
                    font-size: 16px;
                    vertical-align: middle;
                    border-bottom: 1px solid #eeeeee;

                    &:focus {
                        border-bottom: 1px solid rgba(121, 106, 238, 0.9);
                    }
                }
            }

            .button-div {
                height: 60px;
                justify-content: space-between;

                .login-btn {
                    background-color: #796AEE;
                    color: white;
                    height: 40px;
                    width: 60px;
                    border: 1px solid transparent;
                    padding: 0.375rem 0.75rem;
                    font-size: 1rem;
                    line-height: 1.5;
                    border-radius: 0.25rem;

                    &:hover {
                        background-color: #6610f2;
                    }
                }

                .forget {
                    align-items: center;
                    color: #999999;
                }
            }
        }
    }
}

.login-footer {
    color: white;
    height: 30px;
    margin: 10px;
    font-size: 11px;
    text-align: center;
}
</style>