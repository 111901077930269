import {createStore} from 'vuex'

export default createStore({
    state: {
        token: localStorage.getItem('token'),
        expireTime: localStorage.getItem('expireTime'),
        refreshToken: localStorage.getItem('refreshToken'),
        refreshTokenExpireTime: localStorage.getItem('refreshTokenExpireTime'),
        userName: sessionStorage.getItem('userName'),
        factoryId: sessionStorage.getItem('factoryId') ? parseInt(sessionStorage.getItem('factoryId')) : 0,
        currentDoctor: sessionStorage.getItem('currentDoctor') ? JSON.parse(sessionStorage.getItem('currentDoctor')) : {},
        unreadNum: sessionStorage.getItem('unreadNum'),//未读消息数量
        productList: sessionStorage.getItem('productList') ? JSON.parse(sessionStorage.getItem('productList')) : [],//产品列表
        productDetails: sessionStorage.getItem('productDetails') ? JSON.parse(sessionStorage.getItem('productDetails')) : [],//产品详情列表
        doctorList: sessionStorage.getItem('doctorList') ? JSON.parse(sessionStorage.getItem('doctorList')) : [],//医生列表
        dataValueList: sessionStorage.getItem('dataValueList') ? JSON.parse(sessionStorage.getItem('dataValueList')) : [],//数据字典
        toothColorList: sessionStorage.getItem('toothColorList') ? JSON.parse(sessionStorage.getItem('toothColorList')) : [],//牙色
        hospital: sessionStorage.getItem('hospital') ? JSON.parse(sessionStorage.getItem('hospital')) : {},//医院
        plantRetentionList: sessionStorage.getItem('plantRetentionList') ? JSON.parse(sessionStorage.getItem('plantRetentionList')) : [],//打印机设置
        plantSystemList: sessionStorage.getItem('plantSystemList') ? JSON.parse(sessionStorage.getItem('plantSystemList')) : [],//打印机设置
        privatePriceList: [],
        productType: sessionStorage.getItem('productType') ? JSON.parse(sessionStorage.getItem('productType')) : [],//产品类型
        lastProductIds: sessionStorage.getItem('lastProductIds') ? JSON.parse(sessionStorage.getItem('lastProductIds')) : [],//上次录单的产品
        productCount: localStorage.getItem('doctorProductCount') ? JSON.parse(localStorage.getItem('doctorProductCount')) : [],
    },
    getters: {},
    mutations: {
        setExpireTime(state, val) {
            state.expireTime = val
            localStorage.setItem('expireTime', val)
        },
        setRefreshToken(state, val) {
            state.refreshToken = val
            localStorage.setItem('refreshToken', val)
        },
        setRefreshTokenExpireTime(state, val) {
            state.refreshTokenExpireTime = val
            localStorage.setItem('refreshTokenExpireTime', val)
        },
        addProductCount(state, productId) {
            if (state.productCount.length) {
                let item = state.productCount.find(x => x.productId === productId && x.factoryId === state.factoryId)
                if (item) {
                    item.count = item.count + 1
                } else {
                    state.productCount.push({
                        factoryId: state.factoryId,
                        productId: productId,
                        count: 1
                    })
                }
            } else {
                state.productCount.push({
                    factoryId: state.factoryId,
                    productId: productId,
                    count: 1
                })
            }
            if (state.productList.length) {
                let product = state.productList.find(x => x.id === productId)
                if (product) {
                    product.count = (product.count ? product.count : 0) + 1
                }
            }
            localStorage.setItem('doctorProductCount', JSON.stringify(state.productCount))
            sessionStorage.setItem('productList', JSON.stringify(state.productList))
        },
        setLastProductIds(state, val) {
            state.lastProductIds = val
            sessionStorage.setItem('lastProductIds', JSON.stringify(val))
        },
        setProductType(state, val) {
            state.productType = val
            sessionStorage.setItem('productType', JSON.stringify(val))
        },
        setPrivatePriceList(state, val) {
            state.privatePriceList = val
        },
        setToken(state, val) {
            state.token = val
            localStorage.setItem('token', val)
        },
        setUserName(state, val) {
            state.userName = val
            sessionStorage.setItem('userName', val)
        },
        setFactoryId(state, val) {
            state.factoryId = val
            sessionStorage.setItem('factoryId', val)
        },
        setCurrentDoctor(state, val) {
            state.currentDoctor = val
            sessionStorage.setItem('currentDoctor', JSON.stringify(val))
        },
        setUnreadNum(state, val) {
            state.unreadNum = val
            sessionStorage.setItem('unreadNum', val)
        },
        setProductList(state, val) {
            state.productList = val
            sessionStorage.setItem('productList', JSON.stringify(val))
        },
        setProductDetails(state, val) {
            state.productDetails = val
            sessionStorage.setItem('productDetails', JSON.stringify(val))
        },
        setDoctorList(state, val) {
            state.doctorList = val
            sessionStorage.setItem('doctorList', JSON.stringify(val))
        },
        setDataValueList(state, val) {
            state.dataValueList = val
            sessionStorage.setItem('dataValueList', JSON.stringify(val))
        },
        setToothColorList(state, val) {
            state.toothColorList = val
            sessionStorage.setItem('toothColorList', JSON.stringify(val))
        },
        setHospital(state, val) {
            state.hospital = val
            sessionStorage.setItem('hospital', JSON.stringify(val))
        },
        setPlantRetentionList(state, val) {
            state.plantRetentionList = val
            sessionStorage.setItem('plantRetentionList', JSON.stringify(val))
        },
        setPlantSystemList(state, val) {
            state.plantSystemList = val
            sessionStorage.setItem('plantSystemList', JSON.stringify(val))
        },
    },
    actions: {},
    modules: {}
})
