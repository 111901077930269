import http from '@/util/axios'

// 用户管理
export const userLogin = data => http.post('doctorUser/login', data)
export const userLoginHospital = data => http.post('doctorUser/login_hospital', data)
export const userRefreshToken = data => http.factoryPostData('doctorUser/refresh_token', data)
export const userLoginHospitalToken = data => http.factoryPostData('doctorUser/login_hospital_token', data)
export const userResetPassword = data => http.factoryPost('doctorUser/reset_password', data)
export const webStoreGet = data => http.factoryGet('doctorUser/get_web_store', data)
export const loginLogGetPage = data => http.factoryGet('doctorUser/get_login_log_page', data)

// 我的提醒
export const facDoctorAlertGetPage = data => http.factoryGet('facDoctorAlert/get_my_page', data)
export const facDoctorAlertGetUnreadNum = data => http.factoryGet('facDoctorAlert/get_unread_num', data)
export const facDoctorAlertReadAll = data => http.factoryPost('facDoctorAlert/read_all', data)
export const facDoctorAlertReadOne = data => http.factoryPost('facDoctorAlert/read_one', data)
// 文件
export const picUpload = (data, header) => http.postFile('file/upload_pic', data, header)
export const pdfPicUpload = (data, header) => http.postFile('file/upload_pdf_pic', data, header)
export const caseFileUpload = (data, header) => http.postFile('file/upload_case_file', data, header)
export const casePicUpload = (data, header) => http.postFile('file/upload_case_pic', data, header)
export const fileChunkUpload = (data, header) => http.postFile('file/upload_chunk', data, header)
export const fileFinishUpload = data => http.factoryPostData('file/merge_chunk', data)
export const fileGetChunks = data => http.factoryGet('file/get_chunks', data)
