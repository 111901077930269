import {reactive, ref, inject, toRaw, computed} from 'vue'
import {
    caseGetSimplePage,
    caseGet,
    caseGetNo,
    caseGetPage,
    caseSave,
    caseValid,
    caseGetHistoryList,
    caseExpressGet,
    caseProductStepGetSimpleList
} from "@/api/case";
import {useStore} from "vuex";
import {ElNotification, ElMessage} from "element-plus";
import {caseTypeEnum, caseStateEnum, orderSourceEnum, auditStateEnum, toothTypeEnum} from "@/util/enum";

function useSaveCase() {
    const facCase = reactive({
        saveOrderSource: orderSourceEnum.医生下单平台,
        id: 0,
        caseNo: '',
        caseType: 1,
        caseTypeStr: '',
        isTry: 0,
        tryState: '',
        isInvoice: 0,
        isUrgent: 0,
        isValid: 1,
        boxNo: '',
        sourceFactoryId: 0,
        facHospitalId: null,
        facHospitalName: '',
        district: null,
        facDoctorId: null,
        facDoctorName: '',
        facWorkerId: null,
        facWorkerName: '',
        isPrivate: 0,
        agentId: null,
        patientName: '',
        gender: '',
        patientAge: null,
        patientPhoneNo: '',
        hospitalCaseNo: '',
        inDate: new Date(),
        makeDays: null,
        extraDays: null,
        scheduleDeliveryDate: '',
        sourceCaseNo: '',
        factoryRemark: '',
        doctorRemark: '',
        makeCost: '',
        materialCost: '',
        totalPrice: 0,
        caseState: caseStateEnum.已创建,
        orderSource: orderSourceEnum.医生下单平台,
        medicalNo: '',
        redoReason: '',
        plantRetentions: '',
        plantSystems: '',
        express: {
            sendType: '',
            receiverType: '',
            contactPerson: '',
            contactPhoneNo: '',
            province: '',
            city: '',
            area: '',
            address: '',
            isCollect: 0,
            shipPerson: '',
            shipPhoneNo: '',
            shipRemark: '',
            hospitalDeptName: '',
            packDate: '',
        },
        productList: [newCaseProduct()],
        attachList: [{
            id: 0,
            name: '',
            num: 1
        }],
        materialList: [{
            id: 0,
            materialId: '',
            materialNo: '',
            materialName: '',
            lotNo: '',
            registerNo: '',
        }],
        fileList: [],
        plantList: []
    })
    const caseEmpty = JSON.parse(JSON.stringify(toRaw(facCase)))

    // ----------------材料
    function newMaterial() {
        return {
            id: 0,
            materialId: '',
            materialNo: '',
            materialName: '',
            lotNo: '',
            registerNo: '',
        }
    }

    function addMaterial(index) {
        if (facCase.materialList.length >= 15) {
            ElMessage.error('不能超过20条数据')
            return
        }
        facCase.materialList.splice(index, 0, newMaterial())
    }

    function deleteMaterial(index) {
        if (facCase.materialList.length <= 1) {
            Object.assign(facCase.materialList[0], newMaterial())
            return
        }
        facCase.materialList.splice(index, 1)
    }

    // ----------------种植
    function newPlant() {
        return {
            id: 0,
            pos1: '',
            pos2: '',
            pos3: '',
            pos4: '',
            upperJaw: 0,
            lowerJaw: 0,
            num: 0,
            plantSystem: '',
            plantRetention: ''
        }
    }

    function addPlant(index) {
        if (facCase.plantList.length >= 15) {
            ElMessage.error('不能超过20条数据')
            return
        }
        facCase.plantList.splice(index, 0, newPlant())
    }

    function deletePlant(index) {
        if (facCase.plantList.length <= 1) {
            Object.assign(facCase.plantList[0], newPlant())
            return
        }
        facCase.plantList.splice(index, 1)
    }

    function newCaseProduct() {
        return {
            id: 0,
            productId:  null,
            productName:'',
            productNo: '',
            color: '',
            num: null,
            price: 0,
            discount: 100,
            discountType: 0,
            sum: 0,
            benefit: 0,
            pos1: '',
            pos2: '',
            pos3: '',
            pos4: '',
            upperJaw: 0,
            lowerJaw: 0,
            toothType: toothTypeEnum.单冠,
            pontic: '',
            missing1: '',
            missing2: '',
            missing3: '',
            missing4: ''
        }
    }

    const setFields = inject('setFields')
    const initCase = () => {
        setFields(facCase, caseEmpty)
        facCase.productList = [newCaseProduct()]
        facCase.attachList = [{
            id: 0,
            name: '',
            num: 1
        }]
        facCase.materialList = []
        facCase.plantList = []
        addMaterial(0)
        addPlant(0)
    }
    const caseFormRef = ref()
    const validateBeforeSave = () => {
        let validateProductList = facCase.productList.filter(x => !!x.productId)
        if (!validateProductList || !validateProductList.length) {
            ElMessage.error('请填写订单产品')
            return false
        }
        if (validateProductList.some(x => !x.productId || !x.num || x.price === null)) {
            ElMessage.error('每个订单产品都需要选择产品名称，并填写产品数量和单价')
            return false
        }
        if (validateProductList.some(x => x.num < 0 || x.price < 0 || x.sum < 0)) {
            ElMessage.error('产品数量、单价、小计不能小于0')
            return false
        }
        if (facCase.isTry !== 0 && !facCase.tryState) {
            ElMessage.error('请选择试戴类型')
            return false
        }
        facCase.productList = validateProductList
        return true
    }
    const caseSaveRules = reactive({
        caseType: [
            {required: true, message: '请选择订单类型', trigger: 'change'},
        ],
        facHospitalId: [
            {required: true, message: '请选择医院', trigger: 'change'},
        ],
        facDoctorId: [
            {required: true, message: '请选择医生', trigger: 'change'},
        ],
        patientName: [
            {required: true, message: '请输入患者', trigger: 'blur'},
        ],
        inDate: [
            {required: true, message: '请输入入件日期', trigger: 'blur'},
        ],
        totalPrice: [
            {type: 'number', required: true, min: 0, message: '订单金额不能为负数', trigger: 'blur'},
        ],
    })
    const saveCaseLoading = ref(false)
    const saveCaseResult = ref(false)
    const saveCaseInfo = reactive({
        id: 0,
        caseNo: ''
    })
    const saveCase = async () => {
        saveCaseResult.value = false
        if (saveCaseLoading.value) return
        if (!validateBeforeSave()) return
        saveCaseLoading.value = true
        await caseFormRef.value.validate(async (valid, fields) => {
            if (!valid) {
                saveCaseLoading.value = false
                return
            }
            let response = await caseSave(facCase)
            if (response.code === 0) {
                saveCaseResult.value = true
                saveCaseInfo.id = response.data.id
                saveCaseInfo.caseNo = response.data.caseNo
                ElNotification.success('操作成功')
            } else {
                saveCaseResult.value = false
                console.log('factoryPost fail', response)
                ElNotification({
                    title: '失败',
                    message: response.message,
                    type: 'error',
                })
            }
            saveCaseLoading.value = false
        })
    }
    const getCaseTotalPrice = caseInfo => {
        if (caseInfo.caseType === caseTypeEnum.免费 || caseInfo.caseType === caseTypeEnum.试做) {
            if (caseInfo.productList && caseInfo.productList.length) {
                caseInfo.productList.forEach(x => {
                    x.discountType = 0
                    x.discount = 0
                    x.sum = 0
                })
            }
            caseInfo.totalPrice = 0
            return
        }
        let makeCost = caseInfo.makeCost ? parseFloat(caseInfo.makeCost) : 0
        let materialCost = caseInfo.materialCost ? parseFloat(caseInfo.materialCost) : 0
        let productPrice = 0
        if (caseInfo.productList && caseInfo.productList.length) {
            for (let i = 0; i < caseInfo.productList.length; i++) {
                let product = caseInfo.productList[i]
                let num = product.num ? parseFloat(product.num) : 0
                let price = product.price ? parseFloat(product.price) : 0
                let sum = 0
                if (product.discountType === 0) {
                    //百分比
                    let discount = product.discount ? parseFloat(product.discount) : 100
                    product.benefit = (num * price * (100 - product.discount) / 100)
                    sum = num * price * product.discount / 100
                } else {
                    // 金额
                    let discount = product.discount ? parseFloat(product.discount) : 0
                    product.benefit = discount * num
                    sum = num * (price - product.discount)
                }
                product.benefit = Math.round(product.benefit * 100) / 100
                product.sum = Math.round(sum * 100) / 100
                productPrice = (productPrice * 100 + product.sum * 100) / 100
            }
        }
        caseInfo.totalPrice = (makeCost * 100 + materialCost * 100 + productPrice * 100) / 100
    }
    const getTotalPrice = () => {
        getCaseTotalPrice(facCase)
    }
    const totalNum = computed(() => {
        if (facCase.productList && facCase.productList.length) {
            let tmpSum = 0
            for (let product of facCase.productList) {
                let num = product.num ? parseFloat(product.num) : 0
                tmpSum = tmpSum + num
            }
            return tmpSum
        }
        return 0
    })
    const getTotalDiscount = caseInfo => {
        let discountSum = 0
        if (caseInfo.productList && caseInfo.productList.length) {
            for (let product of caseInfo.productList) {
                let num = product.num ? parseFloat(product.num) : 0
                let price = product.price ? parseFloat(product.price) : 0
                if (product.discountType === 0) {
                    //百分比
                    let discount = product.discount ? parseFloat(product.discount) : 100
                    discountSum = discountSum + (num * price * (100 - discount) / 100)
                } else {
                    // 金额
                    let discount = product.discount ? parseFloat(product.discount) : 0
                    discountSum = discountSum + (discount * num)
                }
            }
        }
        return discountSum
    }
    const totalDiscount = computed(() => {
        let discountSum = 0
        if (facCase.productList && facCase.productList.length) {
            for (let product of facCase.productList) {
                let num = product.num ? parseFloat(product.num) : 0
                let price = product.price ? parseFloat(product.price) : 0
                if (product.discountType === 0) {
                    //百分比
                    let discount = product.discount ? parseFloat(product.discount) : 100
                    discountSum = discountSum + (num * price * (100 - discount) / 100)
                } else {
                    // 金额
                    let discount = product.discount ? parseFloat(product.discount) : 0
                    discountSum = discountSum + (discount * num)
                }
            }
        }
        return discountSum
    })
    const changeCaseProductPrice = (row, productDetails, storeHospitalDetail, storeDoctor) => {
        if (!row.productId) return;
        let sameProductNum = facCase.productList.filter(x => x.productId === row.productId)
        if (sameProductNum && sameProductNum.length >= 2) {
            row.productId = ''
            ElMessage.warning('产品重复，请检查')
            return;
        }
        // 获取基础价格
        let storeProductDetail = productDetails.find(x => x.id === row.productId)
        row.productName = storeProductDetail.name
        let salesPrice = 0
        if (storeProductDetail && storeProductDetail.price) {
            salesPrice = parseFloat(storeProductDetail.price)
        }
        // 获取客户价格
        let hospitalPrice = null
        if (storeHospitalDetail && storeHospitalDetail.priceList && storeHospitalDetail.priceList.length) {
            let storeHospitalPrice = storeHospitalDetail.priceList.find(x => x.productId === row.productId)
            if (storeHospitalPrice) hospitalPrice = storeHospitalPrice.salesPrice
        }
        // 如果是私人单位
        if (facCase.isPrivate === 1) {
            if (!storeDoctor) {
                row.price = hospitalPrice ? hospitalPrice : salesPrice
            }else {
                if (storeDoctor.discountId === -1) {
                    //私人单位按照基础价格
                    row.price = salesPrice ? salesPrice : 0
                } else if (storeDoctor.discountId > 0) {
                    //私人单位按照私人价格
                    let doctorPrice = null
                    if (storeDoctor.priceList && storeDoctor.priceList.length) {
                        let storeDoctorPrice = storeDoctor.priceList.find(x => x.productId === row.productId)
                        if (storeDoctorPrice) doctorPrice = storeDoctorPrice.salesPrice
                    }
                    row.price = doctorPrice !== null ? doctorPrice : salesPrice
                } else {
                    //私人单位按照客户价格
                    row.price = hospitalPrice !== null ? hospitalPrice : salesPrice
                }
            }
        } else {
            row.price = hospitalPrice !== null ? hospitalPrice : salesPrice
        }
        if (facCase.caseType !== caseTypeEnum.正常 && row.discountType === 0 && row.discount === 100) {
            //非正常单据默认价格为0（折扣0%）
            row.discount = 0
            row.discountType = 0
        }
        getTotalPrice()
    }
    return {
        addMaterial,
        deleteMaterial,
        addPlant,
        deletePlant,
        newCaseProduct,
        facCase,
        initCase,
        caseFormRef,
        caseSaveRules,
        saveCaseLoading,
        saveCaseResult,
        saveCaseInfo,
        saveCase,
        getCaseTotalPrice,
        getTotalPrice,
        getTotalDiscount,
        changeCaseProductPrice,
        totalDiscount,
        totalNum,
    }
}

// 获取订单
function useGetCase() {
    const caseInfo = reactive({})
    const caseGetLoading = ref(false)
    const getCase = async id => {
        if (!id || id <= 0) return
        if (caseGetLoading.value) return
        caseGetLoading.value = true
        let res = await caseGet({id: id})
        if (!!res) {
            Object.assign(caseInfo, res)
        } else {
            Object.assign(caseInfo, {id: 0})
        }
        caseGetLoading.value = false
    }
    const getCaseByNo = async caseNo => {
        if (!caseNo) return
        if (caseGetLoading.value) return
        caseGetLoading.value = true
        let res = await caseGetNo({caseNo: caseNo})
        if (!!res) {
            Object.assign(caseInfo, res)
        } else {
            Object.assign(caseInfo, {id: 0})
        }
        caseGetLoading.value = false
    }
    return {
        caseInfo,
        caseGetLoading,
        getCase,
        getCaseByNo
    }
}

// 订单分页
function useGetSimplePageCase() {
    const store = useStore()
    const getSimplePageCaseParam = reactive({
        caseNo: '',
        boxNo: '',
        hospitalCaseNo: '',
        sourceCaseNo: '',
        patientName: '',
        facHospitalId: '',
        facDoctorId: '',
        inBeginDate: '',
        inEndDate: '',
        outBeginDate: '',
        outEndDate: '',
        facWorkerId: '',
        salesmanArr: null,
        auditState: null,
        pageIndex: 1,
        pageSize: 10,
    })
    const getSimplePageCaseParamEmpty = JSON.parse(JSON.stringify(toRaw(getSimplePageCaseParam)))
    const initGetSimplePageCaseParam = () => {
        Object.assign(getSimplePageCaseParam, getSimplePageCaseParamEmpty)
        getSimplePageCaseParam.facDoctorId = store.state.currentDoctor.facDoctorId
    }
    const getSimplePageCaseLoading = ref(false)
    const casePage = ref([])
    const getSimplePageCaseResult = reactive({
        totalCount: 0,
        totalPages: 0,
    })
    const getSimplePageCase = async () => {
        if (getSimplePageCaseLoading.value) return
        getSimplePageCaseLoading.value = true
        let data = await caseGetSimplePage(getSimplePageCaseParam)
        if (data && data.list && data.list.length) {
            casePage.value = data.list
            getSimplePageCaseResult.totalPages = Math.ceil(data.totalCount / getSimplePageCaseParam.pageSize)
            getSimplePageCaseResult.totalCount = data.totalCount
        } else {
            casePage.value = []
            getSimplePageCaseResult.totalPages = 0
            getSimplePageCaseResult.totalCount = 0
        }
        getSimplePageCaseLoading.value = false
    }
    return {
        getSimplePageCaseParam,
        initGetSimplePageCaseParam,
        getSimplePageCaseLoading,
        casePage,
        getSimplePageCaseResult,
        getSimplePageCase,
    }
}

// 订单分页
function useGetPageCase() {
    const store = useStore()
    const getPageCaseParam = reactive({
        caseNo: '',
        boxNo: '',
        caseType: [],
        caseTypeStr: '',
        caseState: [],
        maxCaseState: null,
        minCaseState: null,
        caseStateStr: '',
        isTry: -1,
        tryState: null,
        isPrivate: -1,
        isUrgent: -1,
        isInvoice: -1,
        sourceCaseNo: '',
        patientName: '',
        patientPhoneNo: '',
        facHospitalId: '',
        districtId: null,
        facDoctorId: '',
        facWorkerId: null,
        agentId: null,
        medicalNo: '',
        hospitalCaseNo: '',
        inBeginDate: '',
        inEndDate: '',
        outBeginDate: '',
        outEndDate: '',
        scheduleBeginDate: '',
        scheduleEndDate: '',
        isValid: -1,
        isAudit: null,
        isDelay: -1,
        includeProduct: false,
        salesmanArr: null,
        pageIndex: 1,
        pageSize: 10,
    })
    const getPageCaseParamEmpty = JSON.parse(JSON.stringify(toRaw(getPageCaseParam)))
    const initGetPageCaseParam = () => {
        Object.assign(getPageCaseParam, getPageCaseParamEmpty)
        getPageCaseParam.facDoctorId = store.state.currentDoctor.facDoctorId
    }
    const getPageCaseLoading = ref(false)
    const casePage = ref([])
    const getPageCaseResult = reactive({
        totalCount: 0,
        totalPages: 0,
    })
    const getPageCase = async () => {
        if (getPageCaseLoading.value) return
        getPageCaseLoading.value = true
        getPageCaseParam.caseStateStr = getPageCaseParam.caseState.join(',')
        getPageCaseParam.caseTypeStr = getPageCaseParam.caseType.join(',')
        let data = await caseGetPage(getPageCaseParam)
        if (data && data.list && data.list.length) {
            casePage.value = data.list
            getPageCaseResult.totalPages = Math.ceil(data.totalCount / getPageCaseParam.pageSize)
            getPageCaseResult.totalCount = data.totalCount
        } else {
            casePage.value = []
            getPageCaseResult.totalPages = 0
            getPageCaseResult.totalCount = 0
        }
        getPageCaseLoading.value = false
    }
    return {
        getPageCaseParam,
        initGetPageCaseParam,
        getPageCaseLoading,
        casePage,
        getPageCaseResult,
        getPageCase,
    }
}

/**
 * 作废订单
 */
function useValidCase() {
    const validCaseLoading = ref(false)
    const validCaseResult = ref(false)
    const validCaseParam = reactive({
        id: 0,
        validReason: ''
    })
    const validParam = () => {
        if (!validCaseParam.id) {
            ElMessage.warning('请选择作废订单')
            return false
        }
        if (!validCaseParam.validReason) {
            ElMessage.warning('请填写作废原因')
            return false
        }
        return true
    }
    const validCase = async () => {
        if (!validParam()) return
        validCaseResult.value = false
        if (validCaseLoading.value) return
        validCaseLoading.value = true
        validCaseResult.value = await caseValid(validCaseParam)
        validCaseLoading.value = false
    }
    return {
        validCaseLoading,
        validCaseResult,
        validCaseParam,
        validCase,
    }
}

// 获取订单操作日志
function useGetHistoryListCase() {
    const caseHistoryList = ref([])
    const getCaseHistoryListLoading = ref(false)
    const getCaseHistoryList = async id => {
        if (getCaseHistoryListLoading.value) return
        getCaseHistoryListLoading.value = true
        let data = await caseGetHistoryList({id: id})
        if (data) caseHistoryList.value = data
        getCaseHistoryListLoading.value = false
    }
    return {
        caseHistoryList,
        getCaseHistoryListLoading,
        getCaseHistoryList
    }
}

// 获取订单快递信息
function useGetCaseExpress() {
    const getCaseExpressLoading = ref(false)
    const getCaseExpress = async caseId => {
        if (getCaseExpressLoading.value) return
        getCaseExpressLoading.value = true
        let res = await caseExpressGet({id: caseId})
        getCaseExpressLoading.value = false
        return res
    }
    return {
        getCaseExpressLoading,
        getCaseExpress,
    }
}

// 获取订单产品生产过程
function useGetSimpleListCaseProductStep() {
    const caseProductStepSimpleList = ref([])
    const getSimpleListCaseProductStepLoading = ref(false)
    const getSimpleListCaseProductStep = async caseProductId => {
        if (getSimpleListCaseProductStepLoading.value) return
        getSimpleListCaseProductStepLoading.value = true
        let res = await caseProductStepGetSimpleList({id: caseProductId})
        if (res && res.length) {
            caseProductStepSimpleList.value = res.filter(x=>x.operType===2||x.operType===0)
        } else {
            caseProductStepSimpleList.value = []
        }
        getSimpleListCaseProductStepLoading.value = false
    }
    return {
        caseProductStepSimpleList,
        getSimpleListCaseProductStepLoading,
        getSimpleListCaseProductStep
    }
}

export {
    useSaveCase,
    useGetCase,
    useGetPageCase,
    useGetSimplePageCase,
    useValidCase,
    useGetHistoryListCase,
    useGetCaseExpress,
    useGetSimpleListCaseProductStep,
}